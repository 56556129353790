<template>
  <div style="display: flex;">
    <div style="width: 24%;height: 100%;overflow-y: scroll" :style="{height: selfHeight + 'px'}">
      <answer-sheet :selected-option="selectedOption" :answer-sheet-list="answerSheetList"
                    @optionClick="onOptionClick"></answer-sheet>
    </div>


    <div ref="scrollView" style="margin-left: 24px;width: 76%;height: 100%;">
      <div style="margin-bottom: 12px">
        <toggle-question :question-list="questionList" :question-detail="questionDetail"
                         @changeQuestion="onChangeQuestion"></toggle-question>
      </div>
      <div style="height: 100%;overflow-y: scroll" :style="{height: (selfHeight - 72) + 'px'}">
        <view-question :question-list="questionList" :question-detail="questionDetail"
                       @changeQuestion="onChangeQuestion"></view-question>
      </div>
    </div>
  </div>
</template>

<script>
import ViewQuestion from "@/pages/unit_exam/compoments/ViewQuestion";
import AnswerSheet from "@/pages/unit_exam/compoments/AnswerSheet";
import ToggleQuestion from "@/pages/unit_exam/compoments/ToggleQuestion";

export default {
  name: "view-test-paper",
  components: {ViewQuestion, AnswerSheet, ToggleQuestion},
  props: {
    contentHeight: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      questionList: [],
      answerSheetList: [],
      questionDetail: {},
      selectedOption: {},
      selfHeight: 0,
      gId: ""
    }
  },
  watch: {
    contentHeight: {
      handler(val) {
        if (val) {
          this.selfHeight = val
        }
      },
      immediate: true,
      deep: true
    },
  },
  mounted() {
  },

  methods: {
    setGenerator(gId) {
      this.gId = gId
      this.previewBrief()
    },
    async previewBrief() {
      this.questionList = []
      let found = false
      this.answerSheetList = await this.$api.previewBriefUnitExam(this.gId)
      for (let item of this.answerSheetList) {
        if (item.count === 0) {
          continue
        }

        if (!found) {
          await this.getPreviewQuestion(item.type, 0, item.type_name)
          found = true
        }

        for (let i = 0; i < item.count; i++) {
          this.questionList.push({
            type: item.type,
            index: i,
            name: item.type_name
          })
        }

      }
    },

    async getPreviewQuestion(type, index, name) {
      let params = {}
      params.g_id = this.gId
      params.type = type
      params.index = index
      this.questionDetail = await this.$api.getPreviewQuestionUnitExam(params)
      if (this.questionDetail) {
        this.questionDetail.type_name = name
        this.questionDetail.questionIndex = index
        this.selectedOption = {}
        this.selectedOption.cur_type = type
        this.selectedOption.cur_index = index

        if (this.$refs.scrollView) {
          this.$refs.scrollView.scrollTop = 0
        }
      }
    },

    onOptionClick(type, index, name) {
      this.getPreviewQuestion(type, index, name)
    },

    onChangeQuestion(type, index, name) {
      this.getPreviewQuestion(type, index, name)
    },
  },
}
</script>

<style scoped lang="stylus">
</style>
<template>
  <div style="display: flex;">
    <div style="width: 24%;height: 100%;overflow-y: scroll" :style="{height: selfHeight + 'px'}">
      <answer-sheet :selected-option="selectedOption" :answer-sheet-list="answerSheetList"
                    @optionClick="onOptionClick" detail-type="inspect" :questionMap="questionMap"></answer-sheet>
    </div>


    <div ref="scrollView" style="margin-left: 24px;width: 60%;height: 100%;">
      <div style="margin-bottom: 12px">
        <toggle-question :question-list="questionList" :question-detail="questionDetail" detailType="inspect"
                         @changeQuestion="onChangeQuestion" @scoreOptionClick="onScoreOptionClick"></toggle-question>
      </div>
      <div style="height: 100%;overflow-y: scroll" :style="{height: (selfHeight - 72) + 'px'}">
        <view-question :question-list="questionList" :question-detail="questionDetail" detail-type="inspect"
                       @changeQuestion="onChangeQuestion"></view-question>
      </div>
    </div>

    <div style="margin-left: 24px;width: 16%;height: 100%;">
      <div style="border: 1px #eee solid;padding: 24px;font-size: 14px;color: #333;margin: 24px 0">
        <div style="display: flex">
          <div style="position: relative;bottom: 40px;padding-left: 30%">
            <div style="background-color: #fff;padding: 6px 12px;font-size: 18px;font-weight: bold">
              阅卷结果
            </div>
          </div>
        </div>

        <div style="margin-top: -18px">
          <el-form ref="examDetailRef" :model="userExamInfo" label-width="84px">
            <div style="font-size: 16px;font-weight: bold;margin-bottom: 6px">{{ userExamInfo.exam_name }}</div>

            <el-form-item label="考生昵称：">{{ userExamInfo.nick_name }}</el-form-item>
            <el-form-item label="考试总分：">{{ userExamInfo.score ? userExamInfo.score : 0 }}分</el-form-item>
            <el-form-item label="及格分数：">{{ userExamInfo.pass_score ? userExamInfo.pass_score : 0 }}分</el-form-item>
            <el-form-item label="考试得分：">
              <span style="color: #0099ff;">
                {{ userExamInfo.user_score ? userExamInfo.user_score : 0 }}
              </span>分
            </el-form-item>
            <div v-if="mode === 'read'"
                 style="padding-top: 12px;border-top: 1px #eee solid;display: flex;align-items: center;flex-direction: column">
              <span>待评分试题：{{ summaryReadyReadCount }}题</span>
              <el-button type="primary" style="margin-top: 12px" @click="onFinishCLick">保存成绩</el-button>
              <!--            <span style="color:#999;padding: 12px 0">显示全部试题</span>-->
              <!--            <el-switch v-model="isSwitch"></el-switch>-->
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ViewQuestion from "@/pages/unit_exam/compoments/ViewQuestion";
import AnswerSheet from "@/pages/unit_exam/compoments/AnswerSheet";
import ToggleQuestion from "@/pages/unit_exam/compoments/ToggleQuestion";
import {mapMutations} from "vuex";

export default {
  name: "inspect_detail",
  components: {ViewQuestion, AnswerSheet, ToggleQuestion},
  props: {
    contentHeight: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      questionList: [],
      answerSheetList: [],
      questionDetail: {},
      selectedOption: {},
      selfHeight: 0,
      isSwitch: false,
      userExamId: "",
      userExamInfo: {},
      questionMap: {},
      summaryReadyReadCount: 0,
      mode: 'read'
    }
  },
  watch: {
    contentHeight: {
      handler(val) {
        if (val) {
          this.selfHeight = val
        }
      },
      immediate: true,
      deep: true
    },
    userExamId: {
      handler(val) {
        if (val) {
          this.getUserUnitExamInfo(val)
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
  },

  methods: {
    ...mapMutations(["increaseAutoId"]),

    async getUserUnitExamInfo(userExamId) {
      let data = await this.$api.getUserUnitExamInfo(userExamId)
      this.userExamInfo = data
    },

    setUserExamId(userExamId, mode = 'read') {
      this.mode = mode
      this.userExamId = userExamId
      this.getUnitExamReadAnswerSheet(true)
    },
    async getUnitExamReadAnswerSheet(init = false) {
      this.questionList = []
      let found = false
      let data = await this.$api.getUnitExamReadAnswerSheet(this.userExamId)
      this.answerSheetList = data

      for (let item of this.answerSheetList) {
        for (let i = 0; i < item.list.length; i++) {
          let question = item.list[i]
          this.questionList.push({
            type: item.type,
            index: i,
            name: item.type_name,
            qbqId: question.id
          })
          this.questionMap[item.type + "_" + i] = question.status

        }
        item.count = item.list.length
      }

      if (init && this.questionList.length > 0) {
        let first = this.questionList[0]
        this.getPreviewQuestion(first.type, 0, first.name)
      }
      this.summaryReadyReadCount = Object.values(this.questionMap).filter(item => item === 'ready_read').length
    },

    async getPreviewQuestion(type, index, name) {
      console.log(index, "index")

      let q = this.questionList.find(item => item.index === index && item.type === type)
      if (!q) {
        return
      }
      this.questionDetail = await this.$api.getUnitExamReadQuestion(this.userExamId, q.qbqId)
      if (this.questionDetail) {
        this.questionDetail.type_name = name
        this.questionDetail.questionIndex = index
        this.selectedOption = {}
        this.selectedOption.cur_type = type
        this.selectedOption.cur_index = index

        if (this.$refs.scrollView) {
          this.$refs.scrollView.scrollTop = 0
        }
      }
    },

    onOptionClick(type, index, name) {
      this.getPreviewQuestion(type, index, name)
    },

    onChangeQuestion(type, index, name) {
      this.getPreviewQuestion(type, index, name)
    },

    onFinishCLick() {
      this.$emit("finish", this.userExamId, this.summaryReadyReadCount)
    },

    async onScoreOptionClick(id, score, type, index, name) {
      await this.$api.commitReadUnitExamScore(id, score)
      await this.getUnitExamReadAnswerSheet()
      await this.getUserUnitExamInfo(this.userExamId)
      await this.getPreviewQuestion(type, index, name)
    }
  },
}
</script>

<style scoped lang="stylus">
.el-form-item {
  margin-bottom 0
}
</style>
<template>
  <div>
    <div style="border: 1px #eee solid;padding: 12px;margin-right: 24px">
      <div style="display: flex;flex-direction: column;margin-bottom: 24px"
           v-for="(item, index) in this.displayAnswerList"
           :key="index">
        <div>{{ item.type_name }}</div>
        <div style="display: flex;flex-direction: row;flex-wrap: wrap;">
          <div v-for="(option, optionIndex) in item.count" :key="optionIndex"
               style="width: 36px;height: 36px;margin-top: 12px;display: flex;justify-content: center">
            <div class="option"
                 :class="{
              'option-error': curQuestionMap[item.type +'_' + optionIndex] === 'wrong',
              'option-correct': curQuestionMap[item.type +'_' + optionIndex] === 'right',
              'option-ready-read': curQuestionMap[item.type +'_' + optionIndex] === 'ready_read',
              'option-not-done': curQuestionMap[item.type +'_' + optionIndex] === 'not_done',
              'option-selected' : curOptionIndex === optionIndex && curOptionType === item.type,
            }"
                 @click="onOptionClick(item.type, optionIndex, item.type_name)"> {{ option }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="detailType === 'inspect'" style="display:flex;border-top: 1px #eee solid;padding-top: 12px;justify-content: center;flex-wrap: wrap">
<!--      <div style="display: flex;align-items: center;width: 25%;justify-content: center">-->
<!--        <div style="border: #0099ff 1px solid;border-radius: 50px;width: 5px;height: 5px"></div>-->
<!--        <span style="color: #0099ff;padding-left: 6px">已选</span>-->
<!--      </div>-->
        <div style="display: flex;align-items: center;width: 25%;justify-content: center">
          <div style="background-color: #40D1AC;border-radius: 50px;width: 5px;height: 5px"></div>
          <span style="color: #40D1AC;padding-left: 6px">答对</span>
        </div>

        <div style="display: flex;align-items: center;width: 25%;justify-content: center">
        <div style="background-color: #FF1313;border-radius: 50px;width: 5px;height: 5px"></div>
        <span style="color: #FF1313;padding-left: 6px">答错</span>
      </div>


      <div style="display: flex;align-items: center;width: 25%;justify-content: center">
        <div style="background-color: #000;border-radius: 50px;width: 5px;height: 5px"></div>
        <span style="color: #000;padding-left: 6px;">未评分</span>
      </div>

      <div style="display: flex;align-items: center;width: 25%;justify-content: center">
        <div style="background-color: #ccc;border-radius: 50px;width: 5px;height: 5px"></div>
        <span style="color: #ccc;padding-left: 6px">未作答</span>
      </div>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "answer-sheet",
  props: {
    answerSheetList: {
      type: Array,
      default: () => {
        return []
      }
    },
    selectedOption: {
      type: Object,
      default: () => {
        return {}
      }
    },
    detailType: {
      type: String,
      default: "view"
    },
    questionMap: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    selectedOption: {
      handler(val) {
        if (val.cur_type) {
          this.curOptionType = val.cur_type
          this.curOptionIndex = val.cur_index
          console.log(this.curOptionType, this.curOptionIndex, "")
        }
      },
      immediate: true,
      deep: true
    },
    answerSheetList: {
      handler(val) {
        if (this.selectedOption.cur_type) {
          this.curOptionType = this.selectedOption.cur_type
          this.curOptionIndex = this.selectedOption.cur_index
          console.log(this.curOptionType, this.curOptionIndex)
        }

        this.displayAnswerList = []
        for (let item of val) {
          if (item.count > 0) {
            this.displayAnswerList.push(item)
          }
        }
      },
      immediate: true,
      deep: true
    },
    questionMap: {
      handler(val) {
        if (val) {
         this.curQuestionMap = val
          console.log(this.curQuestionMap)
        }
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      curOptionIndex: null,
      curOptionType: null,
      displayAnswerList: [],
      curQuestionMap: {}
    }
  },

  mounted() {
  },

  methods: {
    onOptionClick(type, index, name) {
      this.curOptionIndex = index
      this.curOptionType = type
      this.$emit("optionClick", type, index, name)
    }
  }
}
</script>

<style scoped lang="stylus">
.option {
  border-radius: 50px;
  border: 1px #eee solid;
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  flex-shrink: 0
}

.option-selected {
  border: 1px #0099ff solid;
  color: #0099ff
}

.option-error {
  //border: none
  //background-color: #FF1313
  color: #FF1313
}

.option-correct {
  //border: none
  //background-color: #40D1AC
  color: #40D1AC
}

.option-ready-read {
  color: #000
}

.option-not-done {
  color: #ccc
}
</style>
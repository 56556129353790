<template>
  <div>
    <PageHeaderLayout>
      <div class="main-page-content" ref="contentRef">
        <el-steps :active="active" simple finish-status="success" ref="stepRef">
          <el-step title="基本信息"></el-step>
          <el-step title="选择试卷"></el-step>
          <el-step title="预览试卷"></el-step>
          <el-step title="考试设置"></el-step>
        </el-steps>

        <div :style="{height: contentHeight + 'px'}" style="margin-top: 24px">
          <!--          基本信息-->
          <exam-basic-info ref="basicInfo" v-if="active === 0" :form-data="basicFrom"></exam-basic-info>
          <!--          选择试卷-->
          <select-test-paper @checked="onChecked" ref="paper" v-show="active === 1"
                             :contentHeight="contentHeight"></select-test-paper>
          <!--          预览试卷-->
          <view-test-paper ref="viewRef" :contentHeight="contentHeight" v-show="active === 2"></view-test-paper>
          <!--          考试设置-->
          <exam-setting ref="examSetting" v-show="active === 3" :form-data="basicFrom" :score="score"
                        :count="count"></exam-setting>
        </div>

        <div style="margin-top: 12px;position: absolute;bottom: 60px;right: 48px;">
          <!--          <el-checkbox v-if="active === 2" v-model="saveTemplate" style="padding-right: 12px"-->
          <!--                       @change="changeSaveTemplateStatus">存为模板-->
          <!--          </el-checkbox>-->
          <el-button v-if="active !== 0" @click="onLastClick" type="primary" plain style="margin-right: 6px">上一步</el-button>
          <el-button v-if="active !== 3" @click="onNextClick" type="primary">下一步</el-button>
          <el-button v-if="active === 3" @click="onSubmitUnitExamClick" type="primary">提交</el-button>
        </div>
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout';
import ExamBasicInfo from "@/pages/unit_exam/compoments/ExamBasicInfo";
import SelectTestPaper from "@/pages/unit_exam/compoments/SelectTestPaper";
import ViewTestPaper from "@/pages/unit_exam/compoments/ViewTestPaper";
import ExamSetting from "@/pages/unit_exam/compoments/ExamSetting";
import {mapMutations} from "vuex";

export default {
  name: "x-create-exam",
  components: {PageHeaderLayout, ExamBasicInfo, SelectTestPaper, ViewTestPaper, ExamSetting},
  data() {
    return {
      active: 0,
      saveTemplate: false,
      gId: null,
      basicFrom: {},
      count: '',
      score: '',
      contentHeight: 0,
      unitId: ''
    }
  },
  async mounted() {
    console.log(this.$route.params)
    this.gId = this.$route.params.g_id;
    this.unitId = this.$route.params.unit_id
    let {basic_info} = await this.$api.getGenerateInfo(this.gId)
    this.basicFrom = basic_info
    this.$nextTick(() => {
      this.onResize()
    })
    window.onresize = this.onResize
  },

  watch: {},


  methods: {
    ...mapMutations([
      'saveExamChooseBankList', "increaseAutoId"
    ]),

    onResize() {
      let clientHeight = window.innerHeight
      let stepHeight = this.$refs.stepRef ? this.$refs.stepRef.$el.clientHeight : 0
      console.log(clientHeight, stepHeight)
      let bottomHeight = 60
      console.log(clientHeight - stepHeight - bottomHeight - 24)
      this.contentHeight = clientHeight - stepHeight - bottomHeight - 24 - 64 - 50 - 24 - 24 * 2 - 24
    },

    onChecked(list) {
      this.saveExamChooseBankList(list)
    },

    async onNextClick() {
      if (this.active === 0) {
        if (this.$refs.basicInfo) {
          let obj = this.$refs.basicInfo.getForm()
          this.basicFrom = obj.form //表单数据
          await this.$api.storeUnitExamBasicGenerate(this.gId, this.basicFrom)
          this.active = obj.number

          this.$refs.paper.setGenerator(this.gId)
        }
      } else if (this.active === 1) {
        if (this.$refs.paper.qbList().length === 0) {
          this.$message.error("请选择题库")
          return
        }
        if (this.$refs.paper) {
          if (this.$refs.paper.onSubmitPaperForm()) {
            let data = this.$refs.paper.onSubmitPaperForm()

            console.log(data)
            let form = data.form
            this.count = String(data.count)
            this.score = String(data.score)
            await this.$api.storeUnitExamPaperGenerate(this.gId, form)
            await this.$api.previewUnitExam(this.gId)
            this.$refs.viewRef.setGenerator(this.gId)
            this.active = 2
          }
        }
      } else if (this.active === 2) {
        this.$refs.examSetting.setGenerator(this.gId)
        this.$refs.examSetting.setUnitId(this.unitId)
        this.active = 3
      }
    },

    onLastClick() {
      if (this.active === 1) {
        this.active = 0
      } else if (this.active === 2) {
        this.active = 1
      } else if (this.active === 3) {
        this.active = 2
      }
    },

    changeSaveTemplateStatus(val) {
      console.log(val)
    },

    async onSubmitUnitExamClick() {
      if (this.$refs.examSetting) {
        let obj = this.$refs.examSetting.getForm()
        let params = {
          g_id: this.gId,
          unit_id: this.unitId
        }

        await this.$api.storeUnitExamBasicGenerate(this.gId, obj)
        await this.$api.previewSubmitUnitExam(params)
        let userInfo = localStorage.getItem("user_info")
        userInfo = JSON.parse(userInfo)
        localStorage.removeItem("generate_" + userInfo.id)
        this.increaseAutoId()
        this.$router.back()
      }
    }
  }
}
</script>

<style scoped lang="stylus">
</style>
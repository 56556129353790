<template>
  <div>
    <PageHeaderLayout>
      <ape-table style="padding: 24px;background-color: #fff" ref="apeTable" :data="examInfoList"
                 :columns="examInfoColumns" :loading="loadingExamInfoStatus"
                 :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row>
      </ape-table>

      <div class="main-page-content">
        <el-row class="table-search">
          <el-form :inline="true" :model="formData" class="demo-form-inline">
            <el-form-item label="考生昵称">
              <el-input placeholder="请输入考生昵称" clearable v-model="formData.nick_name"/>
            </el-form-item>
            <el-form-item label="评分状态">
              <el-select v-model="formData.status" style="width: 150px" clearable>
                <el-option v-for="item in inspectStatusList"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="分数区间">
              <div style="display: flex;align-items: center">
                <el-input style="width: 90px" placeholder="最低分数" v-model="formData.begin_score" clearable
                          onkeyup="value=value.replace(/\D/g,'')"
                          :maxLength="3"
                          @blur="onSearchBeginBlur(formData.begin)"/>
                <span style="padding: 0 6px;color: #ccc">一</span>
                <el-input style="width: 90px" placeholder="最高分数" v-model="formData.end_score" clearable
                          onkeyup="value=value.replace(/\D/g,'')"
                          :maxLength="3"/>
              </div>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="onSearchClick" style="margin-left: 12px">搜索</el-button>
            </el-form-item>
            <el-form-item>
              <el-button @click="onResetSearch">重置</el-button>
            </el-form-item>
          </el-form>
        </el-row>

        <ape-table ref="apeTable" :data="examMemberList" :columns="columns" :loading="loadingStatus"
                   :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row>
          <el-table-column v-if="buttonType=='icon'" label="操作" width="80" fixed="right">
            <template slot-scope="scope">
              <span>
                <el-tooltip effect="dark" content="阅卷评分" placement="top-start"
                            v-if="userPermissions.indexOf('unit_exam_inspect') != -1 && scope.row.end_at ">
                  <el-button size="mini" icon="el-icon-document-remove" @click="onInspectClick(scope.row)"></el-button>
                </el-tooltip>
              </span>
            </template>
          </el-table-column>

          <el-table-column v-if="buttonType=='text'" label="操作" width="80" fixed="right">
            <template slot-scope="scope">
              <span>
                  <el-button v-if="userPermissions.indexOf('unit_exam_inspect') != -1 && scope.row.end_at "
                      size="mini" type="text" @click="onInspectClick(scope.row)">阅卷评分</el-button>
              </span>
            </template>
          </el-table-column>

        </ape-table>
      </div>
    </PageHeaderLayout>


    <el-dialog :visible.sync="isInspectDialog" :close-on-click-modal="false" title="阅卷评分" style="margin: 10px"
               :append-to-body="true" fullscreen
               @opened="onOpenedInspectDialog"
               @closed="onCloseInspectDialog" :destroy-on-close="true">
      <inspect-detail ref="inspectRef" :contentHeight="dialogContentHeight" @finish="onFinishUserExam"></inspect-detail>
    </el-dialog>
  </div>
</template>

<script>
const DEL = "del";
const NO_DEL = "no_del";
import PageHeaderLayout from '@/layouts/PageHeaderLayout';
import ApeTable from '@/components/ApeTable';
import InspectDetail from "@/pages/unit_exam/compoments/InspectDetail";

import {mapGetters} from 'vuex';

export default {
  name: "scoring",
  components: {PageHeaderLayout, ApeTable, InspectDetail},
  data() {
    return {
      loadingStatus: false,
      loadingExamInfoStatus: false,
      // 表格列表数据
      examMemberList: [],
      columns: [
        {
          title: '用户Id',
          value: "user_id",
          width: '300'
        },
        {
          title: '考生昵称',
          value: "nick_name",
        },
        {
          title: '考试时间(分钟)',
          value: "cost_time",
          width: '150'
        },
        {
          title: '得分',
          value: 'user_score',
          width: '120'
        },
        {
          title: '开始时间',
          value: "start_at",
          width: '200'
        },
        {
          title: '交卷时间',
          value: "end_at",
          width: '200'
        },
        {
          title: '状态',
          value: 'status',
          value_display: "status_alias",
          style_plan: [
            {
              value: "1",
              style: "color:#67C23A;"
            },
            {
              value: "0",
              style: "color:#F56C6C;"
            }
          ]
        }
      ],
      examInfoList: [],
      examInfoColumns: [
        {
          title: '考试名称',
          value: 'name',
        },
        {
          title: '考试时间(分钟)',
          value: "exam_time",
          width: '180'
        },
        {
          title: '题目数量',
          value: "total_count",
          width: '150'
        },
        {
          title: '总分',
          value: 'score',
          width: '150'
        },
        {
          title: '及格分',
          value: "pass_score",
          width: '150'
        },
        {
          title: '平均分',
          value: "avg_score",
          width: '150'
        },
        {
          title: '参考人数',
          value: "total_user_count",
          width: '150'
        },
        {
          title: '及格人数',
          value: "total_pass_user_count",
          width: '150'
        },
        {
          title: "及格率",
          value: 'pass_per',
          width: '150'
        }
      ],
      formData: {},
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        // offset: 0, // 分页的offset,序号列使用
      },
      isInspectDialog: false,
      dialogContentHeight: 0,
      inspectStatusList: [
        {
          id: 0,
          name: '待阅卷'
        },
        {
          id: 1,
          name: '已阅卷'
        }
      ],
      examId: '',
      selectedRow: {},
    }
  },

  computed: {
    ...mapGetters(['userPermissions', 'buttonType', 'curChooseBankId', 'questionBankInfo', 'curChooseBankName', "autoId"])
  },

  watch: {
    autoId(val) {
      this.initUnitExamMemberList()
    }
  },

  async mounted() {
    // this.examInfoList = []
    this.getReadBriefUnitExamInfo()

    this.$nextTick(() => {
      this.initUnitExamMemberList()
      this.dialogContentHeight = window ? (window.innerHeight - 144) : 0
    })
  },

  methods: {
    async getReadBriefUnitExamInfo() {
      this.loadingExamInfoStatus = true
      this.examId = this.$route.params.id;
      let data = await this.$api.getReadBriefUnitExamInfo(this.examId)
      this.loadingExamInfoStatus = false
      let list = []
      list[0] = data
      this.examInfoList = list
    },

    //搜索
    onSearchClick() {
      this.$refs["apeTable"].resetCurPageSize()
      if (this.formData.begin_score) {
        this.formData.begin_score = parseInt(this.formData.begin_score)
      }

      if (this.formData.end_score) {
        this.formData.end_score = parseInt(this.formData.end_score)
      }
      this.initUnitExamMemberList(DEL, this.formData);
    },

    //重置搜索
    onResetSearch() {
      this.formData = {}
      this.initUnitExamMemberList();
    },

    // 获取参考人员列表
    async initUnitExamMemberList(type, params = {}) {
      this.loadingStatus = true;
      let inputData = this.$refs['apeTable'].getPagingInfo(type);

      for (let param in this.formData) {
        inputData[param] = this.formData[param];
      }

      inputData["unit_exam_id"] = this.examId
      let {list, pages} = await this.$api.getUserUnitExamList(inputData);
      this.examMemberList = [];
      this.$nextTick(() => {
        for (let i in list) {
          list[i].cost_time = Math.floor(list[i].cost_time /= 60)
        }

        this.examMemberList = list
      });

      // this.pagingData.offset = pages.offset;
      this.pagingData.total = pages.total;
      this.loadingStatus = false
    },

    // 切换页码操作
    async switchPaging() {
      this.initUnitExamMemberList(NO_DEL)
    },

    // 最低分数失去光标
    onSearchBeginBlur(val) {
      if (val > this.formData.end_score) {
        this.formData.begin_score = this.formData.end_score
      }
    },

    // 打开阅卷评分
    onInspectClick(row) {
      this.selectedRow = row
      this.isInspectDialog = true
    },
    onOpenedInspectDialog() {
      let mode = this.selectedRow.status === 1 ? 'view' : 'read'
      this.$refs.inspectRef.setUserExamId(this.selectedRow.id, mode)
    },

    // 关闭阅卷评分
    onCloseInspectDialog() {
      this.initUnitExamMemberList()
      this.getReadBriefUnitExamInfo()
    },

    // 提交阅卷评分
    async onFinishUserExam(id, readyReadCount) {
      let message = ""
      if (readyReadCount > 0) {
        message = "还有" + readyReadCount + "题未评分，是否保存成绩，确认后将将无法修改分数"
      } else {
        message = "是否保存成绩，确认后将无法修改分数"
      }

      this.$confirm(message, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.isInspectDialog = false
        await this.$api.finishReadUnitExamScore(id)
      })
    }

  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <div class="main-page-content">
      <el-row class="table-search">
        <el-form :inline="true" :model="formData" class="demo-form-inline">
          <el-form-item label="题库">
            <el-select v-model="formData.bankId" @change="onSelectQbChange" clearable>
              <el-option v-for="item in questionBankList"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="章节名称">
            <el-input placeholder="请输入章节名称" clearable v-model="formData.name"/>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSearchClick" style="margin-left: 12px">搜索</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="onResetSearch">重置</el-button>
          </el-form-item>
        </el-form>
      </el-row>

      <div style="display: flex;justify-content: flex-end;width: 100%">

        <div>
          <div style="display: flex;align-items: center;">
            <div style="padding-right: 12px">已选择 <span style="color:#0099ff;padding: 0 6px">{{
                selectedList.length
              }}</span>项
            </div>
            <el-button v-if="selectedList.length > 0" @click="onClearSelection" type="danger" size="mini" plain>清空已选数据
            </el-button>
            <el-button type="primary" size="mini" plain @click="onFinish" style="margin-left: 12px">完成</el-button>
          </div>
        </div>
      </div>

      <ape-table ref="apeTable" :data="chapterList" :columns="columns" :loading="loadingStatus"
                 :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row
                 @select="handleSelectionChange" @selectAllOnce="onHandleSelectionAllChange">
        <el-table-column slot="first-column"
                         type="selection"
                         :selectable="checkSelectable"
                         width="55">
        </el-table-column>
      </ape-table>

    </div>
  </div>
</template>

<script>
const DEL = "del";
const NO_DEL = "no_del";
import ApeTable from '@/components/ApeTable';

import {mapGetters} from 'vuex';

export default {
  name: "select-chapter",
  props: {
    questionType: {
      type: String,
      default: ""
    },
  },
  components: {ApeTable},
  data() {
    return {
      isView: false,
      loadingStatus: false,
      // 表格列表数据
      chapterList: [],
      columns: [{
        title: '章节名称',
        value: "name",
      },
        {
          title: '题目数量',
          value: 'question_count',
          width: '200'
        },
      ],
      formData: {},
      searchForm: {},
      searchParams: {},

      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        // offset: 0, // 分页的offset,序号列使用
      },
      memberId: '',
      selectedList: [],

      questionBankList: [],
      curQbId: ""
    }
  },

  computed: {
    ...mapGetters(['userPermissions', 'buttonType', 'examChooseBankList'])
  },

  mounted() {
  },

  watch: {
    examChooseBankList: {
      handler(val) {
        if (val.length > 0) {
          if (!this.formData.bankId) {
            let firstBank = val[0]
            this.curQbId = firstBank.id
            this.formData.bankId = this.curQbId
          }
          this.questionBankList = val
          let curId = this.formData.bankId
          let index = this.questionBankList.findIndex(item => item.id === curId)
          if (index === -1) {
            this.formData.bankId = val.length > 0 ? val[0].id : ""
          }
          if (this.formData.bankId !== "") {
            this.initChapterList(DEL)
          }
        }
      },
      immediate: true,
      deep: true
    },
    formData: {
      handler(data) {
        console.log(data.bankId)
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    onSelectQbChange(e) {
      this.formData.bankId = e
      this.setSelectionList()
    },


    //搜索
    onSearchClick() {
      this.$refs['apeTable'].resetCurPageSize();
      // this.curQbId = this.formData.bankId
      this.initChapterList(DEL);
    },

    //重置搜索
    onResetSearch() {
      // this.curQbId = this.formData.bankId
      this.formData = {bankId: this.formData.bankId}
      this.initChapterList(DEL);
    },

    async initChapterList(type, params = {}) {
      if (this.formData.bankId === "") {
        return
      }

      if (!this.$refs['apeTable']) {
        return
      }

      this.loadingStatus = true;
      let inputData = this.$refs['apeTable'].getPagingInfo(type);
      for (let param in params) {
        inputData[param] = params[param];
      }
      if (type === DEL) {
        this.$refs['apeTable'].resetCurPageSize()
        inputData["current_page"] = 1
      }
      inputData["qb_id"] = this.formData.bankId
      inputData["name"] = this.formData.name
      let {list, pages} = await this.$api.getChapterList(inputData);
      this.chapterList = [];
      this.$nextTick(() => {
        this.chapterList = list
        this.setQuestionCount()
      });
      this.pagingData.total = pages.total;
      // this.pagingData.offset = pages.offset;
      this.loadingStatus = false

      // console.log(this.selectedList, "aid")
      setTimeout(() => {
        for (let selected of this.selectedList) {
          this.$refs.apeTable.defaultCheckedRow(selected.chapter_id)
        }
      }, 200)
    },

    //设置题数
    setQuestionCount() {
      switch (this.questionType) {
        case "single_choice":
        case "judge":
        case "essay_questions":
        case "case_analysis":
        case "short":
        case "multiple_choice":
          this.columns[1].value = this.questionType + '_count'
          break
      }
    },

    //判断题数为0禁用
    checkSelectable(row) {
      let count = 0
      switch (this.questionType) {
        case "single_choice":
        case "judge":
        case "essay_questions":
        case "case_analysis":
        case "short":
        case "multiple_choice":
          count = row[this.questionType + '_count']
          break
      }

      return count > 0
    },

    // 切换页码操作
    async switchPaging() {
      await this.initChapterList(NO_DEL)
      // console.log(this.selectedList, "selected")
      // setTimeout(() => {
      //   for (let selected of this.selectedList) {
      //     this.$refs.apeTable.defaultCheckedRow(selected.chapter_id)
      //   }
      // }, 200)

    },

    // 清空选择
    onClearSelection() {
      this.selectedList = []
      this.$refs.apeTable.clearSelection()
      this.initChapterList()
    },

    async setSelectionList(list = null) {
      this.$refs.apeTable.clearSelection()
      await this.initChapterList()
      if (list !== null) {
        this.selectedList = list
      }
      setTimeout(() => {
        for (let selected of this.selectedList) {
          this.$refs.apeTable.defaultCheckedRow(selected.chapter_id)
        }
      }, 200)

    },

    // 全选
    onHandleSelectionAllChange(arr) {
      for (let i of this.chapterList) {
        let index = this.selectedList.findIndex(item => item.chapter_id === i.id)
        if (index > -1) {
          this.selectedList.splice(index, 1)
        }
      }

      if (arr.length > 0) {
        for (let id of arr) {
          console.log(id)
          this.selectedList.push({bank_id: this.formData.bankId, chapter_id: id})
        }
      }
      // if (selected) {
      //   this.selectedList.push({bank_id: this.formData.bankId, chapter_id: id})
      //   this.selectedList = Array.from(new Set(this.selectedList))
      //   return
      // }
      // for (let i of this.chapterList) {
      //   let index = this.selectedList.findIndex(item => item.chapter_id === i.id)
      //   if (index > -1) {
      //     this.selectedList.splice(index, 1)
      //   }
      // }
      // this.$refs.apeTable.clearSelection()

    },

    // 单选
    handleSelectionChange(id, selected) {
      if (selected) {
        this.selectedList.push({bank_id: this.formData.bankId, chapter_id: id})
      } else {
        let pos = this.selectedList.findIndex(item => item.chapter_id === id)
        if (pos > -1) {
          this.selectedList.splice(pos, 1)
        }
      }

    },

    async onFinish() {
      let bankList = this.examChooseBankList.map(item => item.id)
      let arr = []
      for (let bank of bankList) {
        let params = {}
        params["current_page"] = 1
        params["page_size"] = 1000
        params["qb_id"] = bank
        let {list} = await this.$api.getChapterList(params);

        for (let i of this.selectedList) {
          let found = list.find(item => item.id === i.chapter_id)
          if (found) {
            arr.push(found)
          }
        }
      }


      this.$emit("finish", arr, this.questionType)
    }
  }
}
</script>

<style scoped lang="stylus">
.x-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center
}

.color-red {
  color red
}

.color-green {
  color green
}

.main-page-content {
  padding 0
}
</style>
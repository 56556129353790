<template>
  <div>
    <router-view v-show="$route.matched.length==3"></router-view>
    <PageHeaderLayout v-show="$route.matched.length==2">
      <div class="main-page-content">
        <el-tooltip effect="dark" content="添加考试" placement="top-start"
                    v-if="userPermissions.indexOf('unit_exam_create') != -1">
          <el-button type="primary" size="medium" @click="onCreateClick" style="margin-bottom: 12px">添加考试</el-button>
        </el-tooltip>
        <el-row class="table-search">

          <el-form :inline="true" :model="formData" class="demo-form-inline">
            <el-form-item v-if="unitList.length > 0" label="所属单位">
              <el-select v-model="formData.unitId" @change="onSelectUnitChange" clearable>
                <el-option v-for="item in unitList"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="考试名称">
              <el-input placeholder="请输入考试名称" clearable v-model="formData.name"/>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSearchClick" style="margin-left: 12px">搜索</el-button>
            </el-form-item>
            <el-form-item>
              <el-button @click="onResetSearch">重置</el-button>
            </el-form-item>
          </el-form>
        </el-row>

        <ape-table ref="apeTable" :data="examList" :columns="columns" :loading="loadingStatus"
                   :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row>
          <el-table-column

              label="操作"
              width="200"
              fixed="right"
          >

            <template slot-scope="scope">
              <span>
<!--                <el-tooltip effect="dark" content="编辑" placement="top-start" v-if="userPermissions.indexOf('unit_exam_edit') != -1">-->
                <!--                  <el-button size="mini" icon="el-icon-edit" @click="onEditClick(scope.row.id)"></el-button>-->
                <!--                </el-tooltip>-->
                <el-tooltip effect="dark" content="查看" placement="top-start"
                            v-if="userPermissions.indexOf('unit_exam_view') != -1">
                  <el-button size="mini" icon="el-icon-view" @click="onViewClick(scope.row.id)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="成绩统计" placement="top-start"
                            v-if="userPermissions.indexOf('unit_exam_inspect') != -1">
                  <el-button size="mini" icon="el-icon-pie-chart" @click="onScoringClick(scope.row.id)"></el-button>
                </el-tooltip>
                  <el-tooltip effect="dark" content="删除" placement="top-start"
                              v-if="userPermissions.indexOf('unit_exam_delete') != -1">
                  <el-button size="mini" type="danger" icon="el-icon-delete"
                             @click="onDeleteClick(scope.row)"></el-button>
                </el-tooltip>
              </span>
            </template>
          </el-table-column>
        </ape-table>
      </div>
    </PageHeaderLayout>

    <ApeDrawer :drawerData="drawerData" @drawerClose="drawerClose" @drawerConfirm="drawerConfirm">
      <template slot="ape-drawer">
        <el-col class="content-left">
          <el-form ref="examDetailRef" :model="examDetailForm" label-width="100px">
            <el-form-item label="考试名称：">{{ examDetailForm.name }}</el-form-item>
            <el-form-item label="考试日期：">{{ examDetailForm.start_at }} ~ {{ examDetailForm.end_at }}</el-form-item>
            <el-form-item label="考试时间：">{{ examDetailForm.exam_time }}分钟</el-form-item>
            <el-form-item label="考试总分：">{{ examDetailForm.score }}分</el-form-item>
            <el-form-item label="及格分数：">{{ examDetailForm.pass_score }}分</el-form-item>
            <el-form-item label="考试人群：">
              <span v-if="examDetailForm.exam_user_id === 'all'">全部</span>
              <span v-if="examDetailForm.exam_user_id !== 'all'">
                   <div style="display: flex;align-items: center">
                  <span style="padding-right: 12px">指定考生</span>
                  <el-button type="primary" plain size="mini" @click="onViewMemberClick(examDetailForm.exam_user_id, examDetailForm.unit_id)">查看学员</el-button>
                </div>
                </span>
            </el-form-item>
            <el-form-item label="题目数量：">
              <div style="display: flex;align-items: center">
                <span style="padding-right: 12px">{{ examDetailForm.total_count }}题</span>
                <el-button type="primary" plain size="mini" @click="onViewQuestionsClick(examDetailForm.g_id)">查看题目
                </el-button>
              </div>
            </el-form-item>
          </el-form>
        </el-col>
      </template>
    </ApeDrawer>

    <el-dialog :visible.sync="isQuestionsDialog" :close-on-click-modal="false" width="80%" title="查看题目"
               :append-to-body="true" fullscreen style="margin: 10px" @opened="onOpenedViewQuestionDialog" :destroy-on-close="true">
      <view-test-paper ref="viewQuestionRef" :contentHeight="dialogContentHeight"></view-test-paper>
    </el-dialog>

    <el-dialog :visible.sync="isMemberDialog" :close-on-click-modal="false" width="80%" title="查看学员"
               :append-to-body="true" fullscreen style="margin: 10px" @opened="onOpenedViewMemberDialog" :destroy-on-close="true">
      <view-member ref="viewMemberRef"></view-member>
    </el-dialog>

    <el-dialog :visible.sync="isSelectUnitDialog" :close-on-click-modal="false" width="40%" title="选择单位"
               @opened="onOpenedUnitDialog" @closed="onClosedUnitDialog"
               :append-to-body="true" :destroy-on-close="true">
      <select-unit ref="selectUnitRef" :unit-list="unitList" @finish="onFinishUnitClick"></select-unit>
    </el-dialog>
  </div>
</template>

<script>
const DEL = "del";
const NO_DEL = "no_del";
import PageHeaderLayout from '@/layouts/PageHeaderLayout';
import ApeTable from '@/components/ApeTable';
import ApeDrawer from '@/components/ApeDrawer';
import ViewTestPaper from "@/pages/unit_exam/compoments/ViewTestPaper";
import ViewMember from "@/pages/unit_exam/compoments/ViewMember";
import SelectUnit from "@/pages/unit_exam/compoments/SelectUnit";

import {mapGetters} from 'vuex';

export default {
  name: "unit-exam-list",
  components: {PageHeaderLayout, ApeTable, ApeDrawer, ViewTestPaper, ViewMember, SelectUnit},
  data() {
    return {
      isSelectUnitDialog: false,
      loadingStatus: false,
      // 表格列表数据
      examList: [],
      columns: [
        {
          title: '考试名称',
          value: 'name',
          width: '250'
        },
        {
          title: '题目数量',
          value: "total_count",
          width: '80'
        },
        {
          title: '总分',
          value: 'score',
          width: '72'
        },
        {
          title: '及格分',
          value: "pass_score",
          width: '72'
        },
        {
          title: '考试人群',
          value: "exam_crowd",
          width: '90'
        },
        {
          title: '考试时间(分钟)',
          value: "exam_time",
          width: '120'
        },
        {
          title: '开始时间',
          value: "start_at",
          width: '180'
        },
        {
          title: '结束时间',
          value: "end_at",
          width: '180'
        },
        {
          title: "创建时间",
          value: 'created_at',
          width: '180'
        },
        {
          title: '状态',
          value: 'status',
          value_display: "status_alias",
          style_plan: [
            {
              value: "0",
              style: "color:#67C23A;"
            },
            {
              value: "-1",
              style: "color:#F56C6C;"
            }
          ]
        }
      ],
      formData: {},
      searchForm: [
        {
          title: "考试名称",
          value: "name",
        }
      ],
      searchParams: {},

      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        // offset: 0, // 分页的offset,序号列使用
      },

      // 抽屉数据
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        title: '',
        width_height: '560px',
      },

      examDetailForm: {},
      isQuestionsDialog: false,
      gId: "",
      dialogContentHeight: 0,
      memberList: [],
      isMemberDialog: false,
      userInfo: {},
      unitList: [],
      curUnitId: '', //列表UnitId
    }
  },

  computed: {
    ...mapGetters(['userPermissions', 'buttonType', 'curChooseBankId', 'questionBankInfo', 'curChooseBankName', "autoId"])
  },

  watch: {
    autoId(val) {
      this.initUnitExamList()
    },

    userInfo: {
      handler(val) {
        if (val && val.is_manager === 'T') {
          if (this.columns.length === 11) {
            return
          }
          this.columns.unshift({title: '单位名称', value: 'unit_name', width: '300'})
          this.setUnitList()
        }
      },
      immediate: true,
      deep: true
    },
  },

  async mounted() {
    // let userInfo = localStorage.getItem("user_info")
    // userInfo = JSON.parse(userInfo)
    let data = await this.$api.getPassPortUserInfo()
    console.log(data)
    this.userInfo = data

    this.$nextTick(() => {
      this.initUnitExamList()
      this.dialogContentHeight = window ? (window.innerHeight - 144) : 0
    })
  },

  methods: {
    async setUnitList() {
      let params = {}
      params["current_page"] = 1
      params["page_size"] = 1000
      let {list} = await this.$api.getUnitList(params)
      this.unitList = list
    },
    //搜索
    onSearchClick() {
      this.$refs["apeTable"].resetCurPageSize()
      this.initUnitExamList(DEL, this.formData);
    },

    //重置搜索
    onResetSearch() {
      this.formData.name = ""
      this.initUnitExamList();
    },

    async initUnitExamList(type, params = {}) {
      this.loadingStatus = true;
      let inputData = this.$refs['apeTable'].getPagingInfo(type);

      for (let param in params) {
        inputData[param] = this.formData[param];
      }


      if (this.formData.name) {
        inputData['name'] = this.formData.name
      }
      let {list, pages} = await this.$api.getUnitExamList(inputData);
      this.examList = [];
      this.$nextTick(() => {
        for (let i = 0; i < list.length; i++) {
          if (list[i].exam_user_id === "all") {
            list[i].exam_crowd = "全部"
          } else {
            list[i].exam_crowd = "指定考生"
          }

          this.examList = list
        }
      });

      // this.pagingData.offset = pages.offset;
      this.pagingData.total = pages.total;
      this.loadingStatus = false
    },

    // 切换页码操作
    async switchPaging() {
      this.initUnitExamList(NO_DEL)
    },

    // 添加
    async onCreateClick() {
      let gId = localStorage.getItem("generate_" + this.userInfo.id + '_' + this.userInfo.unit_id)
      if (!gId) {
        gId = await this.$api.startUnitExamGenerate()
        localStorage.setItem("generate_" + this.userInfo.id + '_' + this.userInfo.unit_id, gId)
      }

      if (this.userInfo.is_manager === 'T') {
        this.isSelectUnitDialog = true
        return
      }

      this.$router.push(this.$route.path + '/' + gId + '/' + this.userInfo.unit_id + '/create')
    },

    // 编辑
    onEditClick(id) {
      console.log(id)
    },

    // 查看
    async onViewClick(id) {
      if (!id) {
        return
      }

      this.drawerData.loading_text = '玩命加载中……';
      this.drawerData.visible = true;
      this.drawerData.title = '考试详情';
      let {info} = await this.$api.getUnitExamInfo(id)
      this.examDetailForm = info
      this.$nextTick(() => {
        console.log(this.examDetailForm)
        this.drawerData.loading = false;
      })
    },

    // 删除
    onDeleteClick(row) {
      let id = row.id
      let message = ""
      console.log(new Date().getTime(), new Date(row.start_at).getTime(), new Date(row.end_at).getTime())
      console.log(new Date().getTime() - new Date(row.end_at).getTime())

      if (new Date().getTime() < new Date(row.end_at).getTime()) {
        message = "该考试还未结束，是否确认删除该考试"
      } else {
        message = "是否删除该考试"
      }

      this.$confirm(message, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let number = await this.$api.deleteUnitExamInfo(id);
        if (number) {
          this.$nextTick(() => {
            this.$message.error("删除失败")
          })
        } else {
          this.initUnitExamList(DEL);
        }
      })
    },

    // 处理抽屉关闭
    drawerClose() {
      this.drawerData.visible = false;
      this.memberList = []
    },

    // 处理抽屉确认
    async drawerConfirm() {
      this.drawerClose()
    },


    //查看题目
    onViewQuestionsClick(gId) {
      this.gId = gId
      this.isQuestionsDialog = true
    },
    onOpenedViewQuestionDialog() {
      this.$refs.viewQuestionRef.setGenerator(this.gId)
    },

    //查看学员
    async onViewMemberClick(ids, unitId) {
      this.isMemberDialog = true
      this.memberList = []
      let memberIdList = ids.split(',')
      this.memberList = memberIdList
      this.curUnitId = unitId
    },
    onOpenedViewMemberDialog() {
      this.$refs.viewMemberRef.setMemberInfo(this.memberList, this.curUnitId)
    },

    // 阅卷评分
    onScoringClick(id) {
      this.$router.push(this.$route.path + '/' + id + '/inspect')
    },

    onSelectUnitChange(e) {

    },

    onOpenedUnitDialog() {

    },

    onClosedUnitDialog() {

    },

    async onFinishUnitClick(unitId) {
      let gId = localStorage.getItem("generate_" + this.userInfo.id + '_' + unitId)
      if (!gId) {
        gId = await this.$api.startUnitExamGenerate()
        localStorage.setItem("generate_" + this.userInfo.id + '_' + unitId, gId)
      }
      this.isSelectUnitDialog = false
      this.$router.push(this.$route.path + '/' + gId + '/' + unitId + '/create')
    }
  }
}
</script>
<style scoped>

</style>

<template>
  <div v-if="questionDetail.type" style="margin-right: 24px">
<!--    questionList.length > 1-->
<!--    <div v-if="false"-->
<!--        style="background-color: rgba(0, 153, 255, 0.1);height: 60px;display:flex;align-items: center;justify-content: flex-end">-->
<!--      <div v-show="detailType !== 'view'" style="margin-right: 90px;display: flex;flex-direction: row;">-->
<!--        <el-button type="success" icon="el-icon-check" size="small" plain style="margin-right: 6px"></el-button>-->
<!--        <el-button type="danger" icon="el-icon-close" size="small" plain></el-button>-->
<!--        <div style="display: flex;flex-direction: row;align-items: center">-->
<!--          <span style="color: #0099ff;flex-shrink: 0;padding: 0 12px">评分</span>-->
<!--          <el-input v-model="score" style="width: 60px;" size="small"></el-input>-->
<!--          <span style="color: #999;flex-shrink: 0;padding-left: 12px">分</span>-->
<!--        </div>-->
<!--      </div>-->

<!--      <el-button v-if="questionList.findIndex(item => (item.type === questionDetail.type && item.index === questionDetail.questionIndex) ) !== 0" type="primary" plain size="small" style="margin-right: 6px"-->
<!--                 @click="onLastQuestionClick(questionDetail.type, questionDetail.questionIndex)">-->
<!--        上一题-->
<!--      </el-button>-->
<!--      <el-button v-if="questionList.findIndex(item => (item.type === questionDetail.type && item.index === questionDetail.questionIndex) ) !== (questionList.length - 1)" type="primary" plain size="small"-->
<!--                 @click="onNextQuestionClick(questionDetail.type, questionDetail.questionIndex)">-->
<!--        下一题-->
<!--      </el-button>-->
<!--    </div>-->
    <div
        style="border: 1px #eee solid;padding: 24px;font-size: 14px;color: #333;margin: 24px 0">

      <div style="display: flex">
        <div style="position: relative;bottom: 40px;">
          <div style="background-color: #fff;padding: 6px 12px;font-size: 18px;font-weight: bold">
            {{ questionDetail.type_name }}
          </div>
        </div>
      </div>
      <!--单选题-->
      <div v-if="questionDetail.type === 'single_choice'">
        <span>{{ questionDetail.items.topic }}</span>

        <!--选项-->
        <div style="margin-bottom: 60px">
          <div v-for="(option, optionIndex) in questionDetail.items.options" :key="optionIndex"
               style="padding-top: 24px">
            <span>{{ option.option_no }}、</span><span>{{ option.option }}</span>
          </div>
        </div>

        <div v-if="detailType === 'inspect'" style="margin-top: 24px">考生答案： {{ questionDetail.user_answer ? questionDetail.user_answer : '未作答' }}</div>
        <div style="margin-top: 24px">正确答案： {{ questionDetail.items.correct_answer }}</div>
        <div v-if="questionDetail.items.answer_points" style="margin-top: 24px;display: flex">
          <div style="flex-shrink: 0">答案解析：</div>
          <div style="background-color: #f8f8f8;padding: 12px;width: 100%;margin-top: -6px;font-size: 12px;line-height: 18px">
            {{ questionDetail.items.answer_points }}
          </div>
        </div>
      </div>

      <!--      多选题-->
      <div v-if="questionDetail.type === 'multiple_choice'">
        <!--题目-->
        <span>{{ questionDetail.items.topic }}</span>

        <!--选项-->
        <div style="margin-bottom: 60px">
          <div v-for="(option, optionIndex) in questionDetail.items.options" :key="optionIndex"
               style="padding-top: 24px">
            <span>{{ option.option_no }}、</span><span>{{ option.option }}</span>
          </div>
        </div>

        <div v-if="detailType === 'inspect'" style="margin-top: 24px">考生答案： {{ questionDetail.user_answer ? questionDetail.user_answer : '未作答' }}</div>
        <div style="margin-top: 24px">正确答案： {{ questionDetail.items.correct_answer }}</div>
        <div v-if="questionDetail.items.answer_points" style="margin-top: 24px;display: flex">
          <div style="flex-shrink: 0">答案解析：</div>
          <div style="background-color: #f8f8f8;padding: 12px;width: 100%;margin-top: -6px;font-size: 12px;line-height: 18px">
            {{ questionDetail.items.answer_points }}
          </div>
        </div>
      </div>

      <!--      判断-->
      <div v-if="questionDetail.type === 'judge'">
        <!--题目-->
        <span>{{ questionDetail.items.topic }}</span>

        <!--选项-->
        <div style="margin-bottom: 60px">
          <div v-for="(option, optionIndex) in questionDetail.items.options" :key="optionIndex"
               style="padding-top: 24px">
            <span>{{ option.option_no }}、</span><span>{{ option.option }}</span>
          </div>
        </div>

        <div v-if="detailType === 'inspect'" style="margin-top: 24px">考生答案： {{ questionDetail.user_answer ? questionDetail.user_answer : '未作答' }}</div>
        <div style="margin-top: 24px">正确答案： {{ questionDetail.items.correct_answer }}</div>
        <div v-if="questionDetail.items.answer_points" style="margin-top: 24px;display: flex">
          <div style="flex-shrink: 0">答案解析：</div>
          <div style="background-color: #f8f8f8;padding: 12px;width: 100%;margin-top: -6px;font-size: 12px;line-height: 18px">
            {{ questionDetail.items.answer_points }}
          </div>
        </div>
      </div>


      <!--简答题-->
      <div v-if="questionDetail.type === 'short'">
        <!--题目-->
        <span>{{ questionDetail.items.topic }}</span>

        <!--选项-->
        <div>
          <div v-for="(option, optionIndex) in questionDetail.items.options" :key="optionIndex"
               style="padding-top: 24px">

            <div v-if="detailType === 'inspect'">
              <div style="margin-top: 24px;display: flex">
                <div style="flex-shrink: 0">考生答案：</div>
                <div style="background-color: #f8f8f8;padding: 12px;width: 100%;margin-top: -6px;font-size: 12px;line-height: 18px">
                  {{ questionDetail.user_answer ? questionDetail.user_answer : "未填写" }}
                </div>
              </div>
            </div>
            <div style="margin-top: 24px;display: flex">
              <div style="flex-shrink: 0">答案解析：</div>
              <div style="background-color: #f8f8f8;padding: 12px;width: 100%;margin-top: -6px;font-size: 12px;line-height: 18px">
                {{ option.answer_points }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--简答题-->
      <div v-if="questionDetail.type === 'essay_questions'">
        <!--题目-->
        <span>{{ questionDetail.items.topic }}</span>

        <!--选项-->
        <div v-if="detailType === 'inspect'">
            <div style="margin-top: 24px;display: flex">
              <div style="flex-shrink: 0">考生答案：</div>
              <div style="background-color: #f8f8f8;padding: 12px;width: 100%;margin-top: -6px;font-size: 12px;line-height: 18px">
                {{ questionDetail.user_answer ? questionDetail.user_answer : "未填写" }}
              </div>
            </div>
        </div>
        <div>
            <div style="margin-top: 24px;display: flex">
              <div style="flex-shrink: 0">答案解析：</div>
              <div style="background-color: #f8f8f8;padding: 12px;width: 100%;margin-top: -6px;font-size: 12px;line-height: 18px">
                {{ questionDetail.items.options.answer_points }}
              </div>
            </div>
        </div>
      </div>

      <!--案件分析题-->
      <div v-if=" questionDetail.type === 'case_analysis'">
        <div v-for="(topic, topicIndex) in  questionDetail.items.topics" :key="topicIndex" style="margin-bottom: 24px">
          <span>{{ topic.topic }}</span>

          <div style="border: 1px #eee solid;padding: 24px;margin-top: 24px">
            <div v-for="(option, optionIndex) in topic.options" :key="optionIndex" style="margin-bottom: 24px">
              <span v-if="topic.options.length > 1">{{ topicIndex + 1 }}.{{ optionIndex + 1 }}、</span>
              <span v-if="topic.options.length === 1">{{ optionIndex + 1 }}、</span>
              <span>{{ option.program }}</span>
              <div v-if="detailType === 'inspect'" style="margin-top: 24px;display: flex">
                <div style="flex-shrink: 0">考生答案：</div>
                <div style="background-color: #f8f8f8;padding: 12px;width: 100%;margin-top: -6px;font-size: 12px;line-height: 18px">
                  {{ questionDetail.user_answer ? (questionDetail.user_answer[option.id]?questionDetail.user_answer[option.id]:'未填写') : "未填写" }}
                </div>
              </div>
              <div style="margin-top: 24px;display: flex">
                <div style="flex-shrink: 0">答案解析：</div>
                <div style="background-color: #f8f8f8;padding: 12px;width: 100%;margin-top: -6px;font-size: 12px;line-height: 18px">
                  {{ option.answer_points }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "view-question",
  props: {
    questionList: {
      type: Array,
      default: () => {
        return []
      }
    },
    questionDetail: {
      type: Object,
      default: () => {
        return {}
      }
    },
    detailType: {
      type: String,
      default: "view"
    },
  },

  watch: {
    questionDetail: {
      handler(val) {
        if (val.type === 'case_analysis' && val.user_answer && val.user_answer.length > 0) {
          val.user_answer = JSON.parse(val.user_answer)
        }
      },
      immediate: true,
      deep: true
    }
  },

  data() {
    return {
      // score: '',
      caseAnalysisAnswer: {}
    }
  },
  mounted() {
  },

  methods: {
    // onLastQuestionClick(type, index) {
    //   let findIndex = this.questionList.findIndex(item => (item.type === type && item.index === index))
    //   if (findIndex === -1) {
    //     return
    //   }
    //   if (0 === findIndex) {
    //     return
    //   }
    //   let q = this.questionList[findIndex - 1]
    //   this.$emit("changeQuestion", q.type, q.index, q.name)
    // },
    //
    // onNextQuestionClick(type, index) {
    //   let findIndex = this.questionList.findIndex(item => (item.type === type && item.index === index))
    //   if (findIndex === -1) {
    //     return
    //   }
    //   if ((this.questionList.length - 1) === findIndex) {
    //     return
    //   }
    //   let q = this.questionList[findIndex + 1]
    //   this.$emit("changeQuestion", q.type, q.index, q.name)
    // }

    setCaseAnalysisAnswer() {

    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <!--    <router-view v-show="$route.matched.length==3"></router-view>-->
    <PageHeaderLayout v-show="$route.matched.length==2">
      <div class="main-page-content">
        <!--        <el-tooltip effect="dark" content="添加单位" placement="top-start"-->
        <!--                    v-if="userPermissions.indexOf('unit_exam_create') != -1">-->
        <!--          -->
        <!--        </el-tooltip>-->
        <el-button v-if="userPermissions.indexOf('unit_exam_create') != -1" type="primary" size="medium"
                   style="margin-bottom: 12px" @click="onCreateClick">添加单位
        </el-button>

        <el-row class="table-search">
          <el-form :inline="true" :model="searchForm" class="demo-form-inline">
            <el-form-item label="单位名称">
              <el-input placeholder="请输入单位名称" clearable v-model="searchForm.name"/>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSearchClick" style="margin-left: 12px">搜索</el-button>
            </el-form-item>
            <el-form-item>
              <el-button @click="onResetSearch">重置</el-button>
            </el-form-item>
          </el-form>
        </el-row>

        <ape-table ref="apeTable" :data="unitList" :columns="columns" :loading="loadingStatus"
                   :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row>

          <el-table-column
              v-if="buttonType=='icon'"
              label="操作"
              width="240"
              fixed="right"
          >
            <template slot-scope="scope">
              <span>
                <el-tooltip effect="dark" content="学员管理" placement="top-start"
                            v-if="userPermissions.indexOf('unit_edit') != -1">
                  <el-button size="mini" icon="el-icon-user" @click="onMemberClick(scope.row)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="编辑" placement="top-start"
                            v-if="userPermissions.indexOf('unit_edit') != -1">
                  <el-button size="mini" icon="el-icon-edit" @click="onEditClick(scope.row.id)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="查看" placement="top-start"
                            v-if="userPermissions.indexOf('unit_view') != -1">
                  <el-button size="mini" icon="el-icon-view" @click="onViewClick(scope.row.id)"></el-button>
                </el-tooltip>
                  <el-tooltip effect="dark" content="删除" placement="top-start"
                              v-if="userPermissions.indexOf('unit_delete') != -1">
                  <el-button size="mini" type="danger" icon="el-icon-delete"
                             @click="onDeleteClick(scope.row.id)"></el-button>
                </el-tooltip>
              </span>
            </template>
          </el-table-column>

          <el-table-column
              v-if="buttonType=='text'"
              label="操作"
              width="240"
          >
            <template slot-scope="scope">
              <div>
                <el-button size="mini"
                           v-if="userPermissions.indexOf('unit_edit') != -1"
                           @click="onMemberClick(scope.row.id)">学员管理
                </el-button>

                <el-button size="mini"
                           v-if="userPermissions.indexOf('unit_edit') != -1"
                           @click="onEditClick(scope.row.id)">编辑
                </el-button>

                <el-button size="mini"
                           v-if="userPermissions.indexOf('unit_view') != -1"
                           @click="onEditClick(scope.row.id)">查看
                </el-button>

                <el-button size="mini"
                           type="danger"
                           v-if="userPermissions.indexOf('unit_delete') != -1"
                           @click="onDeleteClick(scope.row.id)">删除
                </el-button>
              </div>
            </template>
          </el-table-column>
        </ape-table>
      </div>
    </PageHeaderLayout>

    <ApeDrawer :drawerData="drawerData" @drawerClose="drawerClose" @drawerConfirm="drawerConfirm">
      <template slot="ape-drawer">
        <el-col class="content-left">
          <el-form ref="UnitForm" :model="unitForm" :rules="rules" label-width="100px">
            <div v-if="unitId">
              <el-form-item label="单位编号" prop="id">
                <el-input v-model="unitForm.id" disabled></el-input>
              </el-form-item>
            </div>
            <el-form-item label="单位名称" prop="name">
              <el-input v-model="unitForm.name" :disabled="isView" :maxLength="30"></el-input>
            </el-form-item>
            <el-form-item label="名额" prop="number">
              <el-input v-model="unitForm.number" :maxLength="5" :disabled="isView" @blur="onInput"
                        onkeyup="value=value.replace(/\D/g,'')"></el-input>
            </el-form-item>
            <el-form-item label="管理用户名" prop="account">
              <el-input v-model="unitForm.account" :maxLength="16" :disabled="isView"></el-input>
            </el-form-item>
            <el-form-item label="管理密码" prop="password">
              <div class="x-space-between">
                <span v-if="modify" style="color: #999">* * * * * * * *</span>
                <el-input v-else v-model="unitForm.password" :maxLength="16" :disabled="isView"
                          onkeyup="value=value.replace(/[\u4E00-\u9FA5]/g,'')"></el-input>
                <el-button v-if="drawerData.title === '编辑单位' && modify" type="text" style="padding-left: 24px"
                           @click="onModifyPassworkClick">修改
                </el-button>
                <el-button v-if="drawerData.title === '编辑单位' && !modify" type="text" style="padding-left: 24px"
                           @click="onBackModifyClick">返回
                </el-button>
              </div>
            </el-form-item>
            <el-form-item label="有效期至" prop="expiredType">
              <div class="x-space-between">
                <div v-if="drawerData.title !== '新增单位'">{{ unitForm.expired ? unitForm.expired : '永久' }}</div>
                <el-popover
                    v-if="drawerData.title === '编辑单位' && modify" type="text"
                    placement="bottom"
                    width="100%"
                    trigger="click">

                  <el-radio-group v-model="unitForm.expiredType" style="display: flex;flex-direction: column;">
                    <el-radio :label="0">
                      <el-input v-model="unitForm.expiredNo" :maxLength="3"
                                onkeyup="value=value.replace(/\D/g,'')"
                                style="width: 55%;margin-right: 12px" size="mini"
                                placeholder="请填写单位有效期"
                                @focus="onUnitExpiredInputFocus">
                      </el-input>
                      <el-radio-group v-model="unitForm.expiredUnit" size="mini">
                        <el-radio-button label="d">天</el-radio-button>
                        <el-radio-button label="m">月</el-radio-button>
                        <el-radio-button label="y">年</el-radio-button>
                      </el-radio-group>
                    </el-radio>
                    <el-radio :label="-1" style="padding-top: 24px">永久</el-radio>
                  </el-radio-group>
                  <div style="display: flex;justify-content: flex-end">
                    <el-button size="mini" style="margin-top: 12px;margin-right: 20px" @click="onModifyUnitExpiredSubmitClick">确认</el-button>
                  </div>


                  <el-button slot="reference" style="padding-left: 24px" type="text" @click="resetUnitExpired">{{
                      unitForm.expired ? '续约' : '修改'
                    }}
                  </el-button>
                </el-popover>
              </div>

              <div v-if="drawerData.title === '新增单位'">
                <el-radio-group v-model="unitForm.expiredType" style="padding-top: 8px">
                  <el-radio :label="0">
                    <el-input v-model="unitForm.expiredNo" :maxLength="3"
                              onkeyup="value=value.replace(/\D/g,'')"
                              style="width: 55%;margin-right: 12px" size="mini"
                              placeholder="请填写单位有效期">
                    </el-input>
                    <el-radio-group v-model="unitForm.expiredUnit" size="mini">
                      <el-radio-button label="d">天</el-radio-button>
                      <el-radio-button label="m">月</el-radio-button>
                      <el-radio-button label="y">年</el-radio-button>
                    </el-radio-group>
                  </el-radio>
                  <el-radio :label="-1" style="padding-top: 24px">永久</el-radio>
                </el-radio-group>
              </div>
            </el-form-item>

          </el-form>
        </el-col>
      </template>
    </ApeDrawer>

    <el-dialog :visible.sync="isShowMemberListDialog" :close-on-click-modal="false" width="80%"
               :title="'学员管理('+ curUnitName+')'"
               :append-to-body="true" fullscreen style="margin: 10px" :destroy-on-close="true" @opened="onOpenedMember">
      <member-list ref="memberListRef"></member-list>
    </el-dialog>
  </div>
</template>

<script>
const DEL = "del";
const NO_DEL = "no_del";
import PageHeaderLayout from '@/layouts/PageHeaderLayout';
import ApeTable from '@/components/ApeTable';
import ApeDrawer from '@/components/ApeDrawer';
import MemberList from "@/pages/unit/components/MemberList";

import {mapGetters} from 'vuex';

export default {
  name: "unit-unit-exam-list",
  components: {PageHeaderLayout, ApeTable, ApeDrawer, MemberList},
  data() {
    return {
      isShowMemberListDialog: false,
      isShowUnitExamDialog: false,
      isCreateUnitExamDialog: false,
      modify: false,
      isView: false,
      loadingStatus: false,
      // 表格列表数据
      unitList: [],
      columns: [
        {
          title: '单位编号',
          value: "id",
          width: '300'
        },
        {
          title: '单位名称',
          value: 'name',
          width: "200"
        },
        {
          title: '管理用户名',
          value: 'user_name',
          width: "120"
        },
        {
          title: '名额',
          value: 'number',
          width: '120'
        },
        {
          title: '剩余名额',
          value: "allowed_number",
          width: '120'
        },
        {
          title: "有效期至",
          value: 'expired_at',
          width: '180'
        },
        {
          title: "创建时间",
          value: 'created_at',
          width: '180'
        },
      ],
      formData: {},
      searchForm: {},
      searchParams: {},

      // 抽屉数据
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        title: '',
        width_height: '560px',
      },

      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        // offset: 0, // 分页的offset,序号列使用
      },
      unitForm: {},
      // 表单验证
      rules: {
        name: [
          {required: true, message: '请输入单位名称', trigger: 'blur'}
        ],
        number: [
          {required: true, message: '请填写配置名额', trigger: 'blur'},
        ],
        account: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
          // { min: 8, max: 16, message: '长度在 8 到 16 个字符', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入登录密码', trigger: 'blur'},
          {min: 8, max: 16, message: '长度在 8 到 16 个字符', trigger: 'blur'}
        ],
      },
      unitId: '',
      curUnitName: ''
    }
  },

  computed: {
    ...mapGetters(['userPermissions', 'buttonType', 'curChooseBankId', 'questionBankInfo', 'curChooseBankName'])
  },

  mounted() {
    this.initUnitList();
  },

  watch: {
    modify: {
      handler(val) {
        if (val === true) {
          this.rules.password = []
        } else {
          this.rules.password = {required: true, message: '请输入登录密码', trigger: 'blur'},
              {min: 8, max: 16, message: '长度在 8 到 16 个字符', trigger: 'blur'}
        }
      },
      deep: true
    },

    // 'questionBankInfo'(newVal) {
    //     if (newVal.id) {
    //         this.initRealList();
    //     }
    // }
  },

  methods: {
    //搜索
    onSearchClick() {
      this.$refs['apeTable'].resetCurPageSize();
      this.initUnitList(DEL, this.searchForm);
    },

    //重置搜索
    onResetSearch() {
      this.searchForm = {};
      this.initUnitList();
    },

    async initUnitList(type, params = {}) {
      this.loadingStatus = true;
      let inputData = this.$refs['apeTable'].getPagingInfo(type);
      for (let param in params) {
        inputData[param] = params[param];
      }
      let {list, pages} = await this.$api.getUnitList(inputData);
      this.unitList = [];
      this.$nextTick(() => {
        this.unitList = list
      });
      this.pagingData.total = pages.total;
      // this.pagingData.offset = pages.offset;
      this.loadingStatus = false
    },

    // 切换页码操作
    async switchPaging() {
      this.initUnitList(NO_DEL)
    },

    resetUnitExpired(){
      this.$set(this.unitForm, 'expiredType', 0)
      this.$set(this.unitForm, 'expiredUnit', 'd')
    },

    // 添加
    onCreateClick() {
      this.isView = false
      this.$refs['UnitForm'].resetFields();
      this.unitForm = {}
      this.drawerData.loading_text = '玩命加载中……';
      this.drawerData.visible = true;
      this.drawerData.title = '新增单位';
      this.resetUnitExpired()
      // this.unitForm.id = ''
      this.unitId = ''
      this.$nextTick(() => {
        this.drawerData.loading = false;
      })
    },

    // 编辑
    async onEditClick(id) {
      if (!id) {
        return
      }

      this.unitForm.id = id
      this.unitId = id
      console.log(this.unitForm)
      this.modify = true

      this.drawerData.loading_text = '玩命加载中……';
      this.drawerData.visible = true;
      this.drawerData.title = '编辑单位';
      let {info} = await this.$api.getUnit(id);
      this.$nextTick(() => {
        this.drawerData.loading = false;
        this.unitForm = info;
      })
    },

    onModifyPassworkClick() {
      this.modify = false
    },

    onBackModifyClick() {
      this.modify = true
    },

    // 查看
    async onViewClick(id) {
      if (!id) {
        return
      }

      this.unitForm.id = id
      this.unitId = id
      console.log(this.unitForm)
      this.drawerData.loading_text = '玩命加载中……';
      this.drawerData.visible = true;
      this.drawerData.title = '单位详情';
      this.isView = true
      this.modify = true

      let {info} = await this.$api.getUnit(id);
      this.$nextTick(() => {
        this.drawerData.loading = false;
        this.unitForm = info;
      })
    },

    // 删除
    onDeleteClick(id) {
      this.$confirm('是否删除该单位?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let info = await this.$api.deleteUnit(id);
        if (info == 'ok') {
          this.$nextTick(() => {
            this.initUnitList(DEL);
          })
        } else {
          this.$message.error(info)
        }
      })
    },

    // 初始化数据
    resetFormData() {
      this.$nextTick(() => {
        this.$refs['UnitForm'].resetFields();
      })
    },

    // 处理抽屉关闭
    drawerClose() {
      this.resetFormData();
      // this.isReadOnly = true;
      this.drawerData.visible = false;
      this.modify = false
      this.isView = false
    },

    // 处理抽屉确认
    async drawerConfirm() {
      if (this.isView === true) {
        this.drawerClose()
        return
      }

      //调用组件的数据验证方法
      this.$refs['UnitForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
    },

    // form数据提交，请求接口
    async formSubmit() {
      this.drawerData.loading_text = '玩命提交中……';
      this.drawerData.loading = true;

      if (this.unitForm.expiredType === 0 && !this.unitForm.expiredNo) {
        this.$message.error('请输入单位有效期数值')
        return
      }

      let params = {}
      params["name"] = this.unitForm.name
      params["number"] = this.unitForm.number
      params["account"] = this.unitForm.account
      params["password"] = this.unitForm.password

      if (this.unitForm.expiredType === -1) {
        params["expired"] = -1
      } else {
        params["expired"] = this.unitForm.expiredNo + this.unitForm.expiredUnit
      }

      if (this.unitForm.id) {
        params["id"] = this.unitForm.id
        delete params["expired"]
      }

      let id = await this.$api.saveUnit(params);
      if (id) {
        await this.initUnitList()
        this.$message.success('保存成功!')
      }
      this.$nextTick(() => {
        this.drawerData.visible = false
      });
      this.modify = false
      this.isView = false
      this.resetFormData()
    },

    onInput(e) {
      let val = e.target.value
      let newVal = val
      if (val < 1) {
        newVal = 1
      } else if (val > 10000) {
        newVal = 10000
      }
      e.target.value = newVal
      console.log(this.unitForm.quota)
    },

    onMemberClick(row) {
      this.curUnitName = row.name
      this.unitId = String(row.id)
      this.isShowMemberListDialog = true
    },
    onOpenedMember() {
      this.$refs.memberListRef.setUnitId(this.unitId)
    },

    async onModifyUnitExpiredSubmitClick() {
      if (this.unitForm.expiredType === 0 && !this.unitForm.expiredNo) {
        this.$message.error('请输入单位有效期数值')
        return
      }

      let params = {}
      params["id"] = this.unitForm.id

      if (this.unitForm.expiredType === -1) {
        params["expired"] = -1
      } else {
        params["expired"] = this.unitForm.expiredNo + this.unitForm.expiredUnit
      }

      console.log(params)
      this.drawerData.loading = true;
      await this.$api.changeUnitExpired(params)
      let {info} = await this.$api.getUnit(params.id);
      this.$nextTick(() => {
        this.drawerData.loading = false;
        this.unitForm = info;
      })
    },

    onUnitExpiredInputFocus() {
      this.$set(this.unitForm, 'expiredType', 0)
      this.$set(this.unitForm, 'expiredUnit', 'd')
    }

  }
}
</script>

<style scoped lang="stylus">
.x-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center
}
</style>
<template>
  <div>
    <el-form ref="form" :model="formData" :rules="rules" label-width="90px">

      <el-form-item label="考试名称" prop="name">
        <el-input v-model="formData.name"></el-input>
      </el-form-item>

      <el-form-item label="考试状态" prop="status">
        <el-radio-group v-model="formData.status">
          <el-radio :label="0" border>启用</el-radio>
          <el-radio :label="-1" border>禁用</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="考试时间" prop="timeVal">
        <el-date-picker
            v-model="dateValue"
            @change="onChange"
            type="datetimerange"
            align="left"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
    </el-form>

  </div>
</template>

<script>
export default {
  name: "exam-basic-info",
  props: {
    formData: {
      type: Object,
      default: () => {
        return {};
      }
    },
  },
  data() {
    return {
      rules: {
        name: [
          {required: true, message: '请输入考试名称', trigger: 'blur'},
        ],
        status: [
          {required: true, message: '请选择考试状态', trigger: 'blur'},
        ],
        timeVal: [
          // {required: true, message: '请选择考试时间', trigger: 'change'},
        ],
      },
      dateValue: [],
    }
  },

  mounted() {

  },
  watch: {
    formData: {
      handler(val) {
        if (!val.status) {
          this.$set(this.formData, "status", 0)
        }
        this.setInfo(val)
      },
      immediate:true,
      deep: true
    }
  },

  methods: {


    onChange(e) {
      console.log(e)
      if (this.dateValue.length > 0) {
      this.formData.start_at = this.dateValue[0].getTime()
      this.formData.end_at = this.dateValue[1].getTime()
      console.log(this.formData)
      }
    },

    setInfo(val) {
      this.dateValue = []
      if (val.start_at && val.end_at) {
        this.dateValue.push(new Date(val.start_at))
        this.dateValue.push(new Date(val.end_at))
      }
    },

    getForm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          //
        } else {
          // this.$message.error('提交失败')
        }
      });

      if (this.dateValue.length > 0) {
        return {number: 1, form: this.formData}
      } else {
        if (!this.formData.name && !this.formData.status) {
          this.$message.error('提交失败')
        } else {
          this.$message.error('请选择考试时间')
        }
        return {number: 0, form: this.formData}
      }
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <div>
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" style="margin-bottom: 24px"
                   @change="handleCheckAllChange">全选
      </el-checkbox>
      <el-checkbox-group v-model="checkedQuestionBankIdList" @change="handleCheckedCitiesChange">
        <el-checkbox :label="item.id" v-for="(item, index) in questionBankList" :key="index" style="margin-bottom: 6px">{{ item.name }}({{item.question_count}})</el-checkbox>
      </el-checkbox-group>
    </div>


    <div
        style="display: flex;justify-content: flex-end;width: 100%;margin-top: 24px;padding-top: 12px;border-top: 1px #cccccc solid">
      <el-button type="primary" @click="onSubmit">完成</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "select-question-bank",
  props: {
    questionBankList: {
      type: Array,
      default: () => {
        return []
      }
    },
    info: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },

  data() {
    return {
      checkedQuestionBankIdList: [],
      isIndeterminate: true,
      checkAll: false,
      qbId: ""
    }
  },

  watch: {
    info: {
      handler(val) {
        // console.log(val, "val")
        // this.qbId = val.id
        // this.checkedQuestionBankIdList = val.qb_list
        //
        // this.changeCheckboxStatus()
      },
      deep: true,
      immediate: true
    },
  },

  mounted() {

  },

  methods: {

    setDefaultSelected(val) {
      this.checkedQuestionBankIdList = val
    },

    resetAll() {
      this.checkedQuestionBankIdList = []
    },

    async onSubmit() {

      this.$emit("finish", this.checkedQuestionBankIdList)
    },

    changeCheckboxStatus() {
      this.checkAll = this.checkedQuestionBankIdList.length === this.questionBankList.length;
      this.isIndeterminate = this.checkedQuestionBankIdList.length > 0 && this.checkedQuestionBankIdList.length < this.questionBankList.length;
    },

    handleCheckAllChange(val) {
      this.checkedQuestionBankIdList = [];
      if (val) {
        for (let item of this.questionBankList) {
          this.checkedQuestionBankIdList.push(item.id)
        }
      }
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange() {
      this.changeCheckboxStatus()
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div :style="{height: selfHeight + 'px'}" style="overflow-y: scroll">

    <div v-if="testPaperSource === '0'">
      <div class="x-row" style="margin-bottom: 24px">
        <el-button type="primary" size="small" @click="onSelectQuestionBankClick">选择题库</el-button>
        <div style="color: #999;padding-left: 12px">已选择<span
            style="color:#0099ff;padding: 0 6px">{{ selectedQbIdList.length }}</span>项
        </div>
      </div>

      <div v-show="selectedQbIdList.length > 0">
        <div class="x-space-between" style="margin-bottom: 12px">
          <div style="font-size: 18px;margin-bottom: 12px">题型设置</div>
          <!--          <el-button type="primary" size="mini">选择已有试卷</el-button>-->
        </div>
        <el-tabs v-model="activeName" type="card" @tab-click="onTabsClick">
          <el-tab-pane label="随机组卷" name="random">
            <question-type-set v-if="activeName === 'random'" ref="randomPaper" :selected-bank-list="selectedQbList"
                               testPaperType="random"
                               :question-type="displayQuestionTypeSetList"
                               @change="onQuestionTypeSetChanged('random', $event)"></question-type-set>
          </el-tab-pane>

          <el-tab-pane label="章节组卷" name="chapter">
            <question-type-set v-if="activeName === 'chapter'" ref="chapterPaper" :selected-bank-list="selectedQbList"
                               testPaperType="chapter"
                               :question-type="displayQuestionTypeSetList"
                               @change="onQuestionTypeSetChanged('chapter', $event)"></question-type-set>
          </el-tab-pane>

          <el-tab-pane label="手动组卷" name="manual">
            <question-type-set v-if="activeName === 'manual'" ref="manualPaper" :selected-bank-list="selectedQbList"
                               testPaperType="manual"
                               :question-type="displayQuestionTypeSetList"
                               @change="onQuestionTypeSetChanged('manual', $event)"></question-type-set>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <el-dialog :visible.sync="isSelectQuestionBankDialog" :close-on-click-modal="false" width="40%" title="选择题库"
               @opened="onOpened" @closed="onClosed"
               :append-to-body="true" :destroy-on-close="true">
      <select-question-bank ref="qbRef" :question-bank-list="questionBankList" :info="qbInfo"
                            @finish="onFinishQb"></select-question-bank>
    </el-dialog>
  </div>
</template>

<script>
import QuestionTypeSet from "@/pages/unit_exam/compoments/QuestionTypeSet";
import SelectQuestionBank from "@/pages/unit_exam/compoments/SelectQuestionBank";

export default {
  name: "exam-basic-info",
  components: {QuestionTypeSet, SelectQuestionBank},
  props: {
    contentHeight: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      testPaperSource: "0",
      activeName: "random",
      isSelectQuestionBankDialog: false,
      questionBankList: [],
      qbInfo: {},
      selectedQbIdList: [],
      selectedQbList: [],
      testPaperType: 'random',
      questionTypeCounts: {},
      selfHeight: 0,
      gId: "",
      allQuestionTypeSet: [],
      displayQuestionTypeSetList: []
    }
  },

  watch: {
    contentHeight: {
      handler(val) {
        if (val) {
          this.selfHeight = val
        }
      },
      immediate: true,
      deep: true
    },
  },

  mounted() {
    this.getQuestionType()
  },

  methods: {
    setGenerator(gId) {
      this.gId = gId
      this.getGenerateInfo()
    },

    async getGenerateInfo() {
      let {paper_info} = await this.$api.getGenerateInfo(this.gId)

      if (paper_info) {
        if (paper_info.type === 'random') {
          this.activeName = 'random'
        } else if (paper_info.type === 'chapter') {
          this.activeName = 'chapter'
        } else if (paper_info.type === 'manual') {
          this.activeName = 'manual'
        }
        if (paper_info.question_bank_list) {
          this.questionBankList = await this.$api.getUnitExamBankList()
          this.selectedQbIdList = paper_info.question_bank_list
          let checkedList = []
          for (let id of this.selectedQbIdList) {
            let found = this.questionBankList.find(item => item.id === id)
            if (found) {
              checkedList.push(found)
            }
          }
          this.selectedQbList = checkedList
          this.$emit("checked", checkedList)

          console.log(paper_info.type, 'type')

          if (paper_info.type === 'random') {
            this.$refs.randomPaper.setSelectedRandomQuestionList(paper_info)
          } else if (paper_info.type === 'chapter') {
            this.$refs.chapterPaper.setSelectedChapterQuestionList(paper_info)
          } else if (paper_info.type === 'manual') {
            this.$refs.manualPaper.setSelectedManualQuestionList(paper_info)
          }
        }
      }
    },

    //获取题型
    async getQuestionType() {
      this.allQuestionTypeSet = []
      let list = await this.$api.commonDict('question_category');

      let questionTypeList = list.filter(item => item.id !== "all")
      for (let tab of ["random", 'chapter', 'manual']) {
        this.onQuestionTypeSetChanged(tab, questionTypeList)
      }

      this.defaultTabSelect()
    },
    qbList() {
      return this.selectedQbIdList
    },

    async onSelectQuestionBankClick() {
      this.questionBankList = await this.$api.getUnitExamBankList()

      this.isSelectQuestionBankDialog = true
    },

    onOpened() {
      this.$refs.qbRef.setDefaultSelected(this.selectedQbIdList)
    },

    onClosed() {
      this.$refs.qbRef.resetAll()
    },

    onSubmitPaperForm() {
      let val = this.activeName
      if (val === 'random') {
        let data = this.$refs.randomPaper.submitForm()
        return data
      }
      if (val === 'chapter') {
        let data = this.$refs.chapterPaper.submitForm()
        return data
      }
      if (val === 'manual') {
        let data = this.$refs.manualPaper.submitForm()
        return data
      }
    },

    onFinishQb(list) {
      this.isSelectQuestionBankDialog = false
      this.selectedQbIdList = list


      let checkedList = []
      for (let id of this.selectedQbIdList) {
        let found = this.questionBankList.find(item => item.id === id)
        if (found) {
          checkedList.push(found)
        }
      }

      this.selectedQbList = checkedList

      let tabName = this.activeName
      let ref = null
      if (tabName === 'random') {
        ref = this.$refs.randomPaper
      } else if (tabName === 'chapter') {
        ref = this.$refs.chapterPaper
      } else if (tabName === 'manual') {
        ref = this.$refs.manualPaper
      }

      if (ref) {
        ref.reCalcQuestionTypeSet(this.selectedQbList)
      }

      this.$emit("checked", checkedList)

    },

    defaultTabSelect() {
      let list = this.getQuestionTypeSetListBy("random")
      let json = JSON.stringify(list)
      this.displayQuestionTypeSetList = JSON.parse(json)
    },

    //tabs点击
    onTabsClick(e) {
      let tabName = e.name
      let list = this.getQuestionTypeSetListBy(tabName)
      let json = JSON.stringify(list)
      this.displayQuestionTypeSetList = JSON.parse(json)
      // let val = e.name
      // if (val === 'random') {
      //   if (this.$refs.randomPaper) {
      //     this.$refs.randomPaper.resetAllForm()
      //   }
      // } else if (val === 'chapter') {
      //   if (this.$refs.chapterPaper) {
      //     this.$refs.chapterPaper.resetAllForm()
      //   }
      // } else if (val === 'manual') {
      //   if (this.$refs.manualPaper) {
      //     this.$refs.manualPaper.resetAllForm()
      //   }
      // }
    },

    onQuestionTypeSetChanged(tabName, questionTypeSetList) {
      console.log(tabName + "改变了", questionTypeSetList)
      let found = this.allQuestionTypeSet.find(item => item.tab === tabName)
      if (found) {
        found.list = questionTypeSetList
      } else {
        let obj = {
          tab: tabName,
          list: questionTypeSetList,
        }

        this.allQuestionTypeSet.push(obj)
      }

      console.log(this.allQuestionTypeSet, "dsa")
    },

    getQuestionTypeSetListBy(tabName) {
      let found = this.allQuestionTypeSet.find(item => item.tab === tabName)
      if (found) {
        let list = found.list
        for (let item of list) {
          if (! item.question_count) {
            item.question_count = 0
          }

          if (! item.question_score) {
            item.question_score = 0
          }
        }

        return list;
      }


      return []
    }

    // setQuestionTypeCount() {
    //   let counts = {}
    //   let arr = []
    //   for (let i of this.selectedQbIdList) {
    //     arr.push(this.questionBankList.find(item => item.id === i))
    //   }
    //
    //   counts["case_analysis"] = this.getQuestionTypeCount(arr, "case_analysis_count")
    //   counts["essay_questions"] = this.getQuestionTypeCount(arr, "essay_questions_count")
    //   counts["multiple_choice"] = this.getQuestionTypeCount(arr, "multiple_choice_count")
    //   counts["short"] = this.getQuestionTypeCount(arr, "short_count")
    //   counts["single_choice"] = this.getQuestionTypeCount(arr, "single_choice_count")
    //   counts["judge"] = this.getQuestionTypeCount(arr, "judge_count")
    //
    //   return counts
    // },
    //
    // getQuestionTypeCount(arr, val) {
    //   let count = 0
    //   let list = arr.map(item => item[val])
    //   for (let i = 0; i < list.length; i++) {
    //     count += +list[i];
    //   }
    //
    //   return count
    // }
  }
}
</script>

<style scoped lang="stylus">
/deep/ .el-tabs__nav-scroll {
  background-color: #F8F8F8;
  border: 1px #f2f2f2 solid;
  border-bottom: none;
}

/deep/ .el-tabs__item.is-active {
  background-color #ffffff
}

/deep/ .el-tabs__nav.is-top {
  border: none;
}

/deep/ .el-tabs__item.is-top {
  border: none;
}

.x-row {
  display: flex;
  flex-direction: row;
  align-items: center
}

.x-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center
}
</style>
<template>
  <div>
    <div class="main-page-content">
      <el-row class="table-search">
        <el-form :inline="true" :model="formData" class="demo-form-inline">
          <el-form-item label="手机号码">
            <el-input placeholder="请输入学员手机号码" v-model="formData.phone"/>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSearchClick" style="margin-left: 12px">搜索</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="onResetSearch">重置</el-button>
          </el-form-item>
        </el-form>
      </el-row>
      <div class="main-page-content">
        <ape-table ref="apeTable" :data="memberList" :columns="columns" :loading="loadingStatus"
                   :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row>
        </ape-table>
      </div>
    </div>
  </div>
</template>

<script>
const DEL = "del";
const NO_DEL = "no_del";
import ApeTable from '@/components/ApeTable';

import {mapGetters} from 'vuex';

export default {
  name: "view-member",
  props: {},
  components: {ApeTable},
  data() {
    return {
      isView: false,
      loadingStatus: false,
      // 表格列表数据
      memberList: [],
      memberIdList: [],
      columns: [
        {
          title: '学员编号',
          value: "id",
          width: '200'
        },
        {
          title: '手机号码',
          value: "phone",
          width: '120'
        },
        {
          title: '所属单位',
          value: 'unit_name',
          width: '120'
        },
        {
          title: "创建时间",
          value: 'created_at',
        },
      ],
      formData: {},
      searchForm: [
        {
          title: "手机号码",
          value: "phone",
        }
      ],
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        // offset: 0, // 分页的offset,序号列使用
      },
      curUnitId: '',
      userInfo: {}
    }
  },

  computed: {
    ...mapGetters(['userPermissions', 'buttonType', 'curChooseBankId', 'questionBankInfo', 'curChooseBankName'])
  },

  mounted() {
  },

  watch: {},

  methods: {
    //搜索
    onSearchClick() {
      this.initMemberList(DEL, this.formData);
    },

    //重置搜索
    onResetSearch() {
      this.formData.phone = ''
      this.initMemberList();
    },

    async initMemberList(type, params = {}) {
      this.loadingStatus = true;
      let inputData = this.$refs['apeTable'].getPagingInfo(type);
      for (let param in params) {
        inputData[param] = params[param];
      }
      if (this.curUnitId) {
        inputData["unit_id"] = this.curUnitId
      }
      let {list, pages} = await this.$api.getUnitMemberList(inputData);

      this.memberList = [];
      this.$nextTick(() => {
        for (let userId of this.memberIdList) {
          let viewMember = list.find(item => item.user_id == userId)
          if (viewMember) {
            this.memberList.push(viewMember)
          }
        }
      });
      this.pagingData.total = pages.total;
      // this.pagingData.offset = pages.offset;
      this.loadingStatus = false
    },

    // 切换页码操作
    async switchPaging() {
      this.initMemberList(NO_DEL)
    },

    async setMemberInfo(list, unitId) {
      this.memberIdList = list
      this.userInfo = await this.$api.getPassPortUserInfo()
      if (this.userInfo && this.userInfo.is_manager === "T") {
        this.curUnitId = unitId
      }

      if (this.memberIdList.length > 0) {
        this.initMemberList()
      }
    },
  }
}
</script>

<style scoped lang="stylus">
.x-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center
}

.color-red {
  color red
}

.color-green {
  color green
}

.main-page-content {
  padding 0
}
</style>
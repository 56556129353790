<template>
  <div>
    <div class="main-page-content">
      <el-row class="table-search">
        <el-form :inline="true" :model="formData" class="demo-form-inline">
          <!--          <el-form-item label="题库">-->
          <!--            <el-select v-model="formData.bankId" @change="onSelectQbChange">-->
          <!--              <el-option v-for="item in questionBankList"-->
          <!--                         :key="item.id"-->
          <!--                         :label="item.name"-->
          <!--                         :value="item.id"-->
          <!--              ></el-option>-->
          <!--            </el-select>-->
          <!--          </el-form-item>-->
          <el-form-item label="手机号码">
            <el-input placeholder="请输入手机号码" clearable v-model="formData.phone"/>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSearchClick" style="margin-left: 12px">搜索</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="onResetSearch">重置</el-button>
          </el-form-item>
        </el-form>
      </el-row>

      <div style="display: flex;justify-content: flex-end;width: 100%">

        <div>
          <div style="display: flex;align-items: center;">
            <div style="padding-right: 12px">已选择 <span style="color:#0099ff;padding: 0 6px">{{
                selectedList.length
              }}</span>项
            </div>
            <el-button v-if="selectedList.length > 0" @click="onClearSelection" type="danger" size="mini" plain>清空已选数据
            </el-button>
            <el-button type="primary" size="mini" plain @click="onFinish" style="margin-left: 12px">完成</el-button>
          </div>
        </div>
      </div>

      <ape-table ref="apeTable" :data="memberList" :columns="columns" :loading="loadingStatus"
                 :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row
                 @select="handleSelectionChange" @selectAllOnce="onhandleSelectionAllChange">
        <el-table-column slot="first-column"
                         type="selection"
                         :selectable="checkSelectable"
                         width="55">
        </el-table-column>
      </ape-table>

    </div>
  </div>
</template>

<script>
const DEL = "del";
const NO_DEL = "no_del";
import ApeTable from '@/components/ApeTable';

import {mapGetters} from 'vuex';

export default {
  name: "select-member",
  props: {},
  components: {ApeTable},
  data() {
    return {
      isView: false,
      loadingStatus: false,
      // 表格列表数据
      memberList: [],
      columns: [{
        title: '学员编号',
        value: "id",
        width: '200'
      },
        {
          title: '手机号码',
          value: 'phone',
          width: '300'
        },
        {
          title: '所属单位',
          value: 'unit_name',
        },
        {
          title: '创建时间',
          value: 'created_at',
          width: '400'
        },
      ],
      formData: {},
      searchForm: {},
      searchParams: {},

      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        // offset: 0, // 分页的offset,序号列使用
      },
      memberId: '',
      selectedList: [],

      questionBankList: [],
      curQbId: "",
      unitId: ''
    }
  },

  computed: {
    ...mapGetters(['userPermissions', 'buttonType', 'examChooseBankList'])
  },

  mounted() {

  },

  watch: {
    examChooseBankList: {
      handler(val) {
        if (val.length > 0) {
          if (!this.formData.bankId) {
            let firstBank = val[0]
            this.curQbId = firstBank.id
            this.formData.bankId = this.curQbId
          }
          }
        // this.questionBankList = val
        // this.initMemberList()
        // }
      },
      immediate: true,
      deep: true
    },

  },

  methods: {
    // onSelectQbChange(e) {
    //   this.curQbId = e
    //   this.initMemberList()
    // },
    setUnitId(unitId) {
      if(unitId) {
        this.unitId = unitId
        this.initMemberList(DEL);
      }
    },


    //搜索
    onSearchClick() {
      this.$refs['apeTable'].resetCurPageSize();
      this.initMemberList(DEL);
    },

    //重置搜索
    onResetSearch() {
      this.formData.phone = ""
      this.initMemberList(DEL);
    },

    async initMemberList(type, params = {}) {
      if (this.curQbId === "") {
        return
      }

      if (!this.$refs['apeTable']) {
        return
      }

      this.loadingStatus = true;
      let inputData = this.$refs['apeTable'].getPagingInfo(type);
      for (let param in params) {
        inputData[param] = params[param];
      }
      inputData["phone"] = this.formData.phone
      inputData["unit_id"] = this.unitId
      if (type === DEL) {
        this.$refs['apeTable'].resetCurPageSize()
        inputData["current_page"] = 1
      }
      let {list, pages} = await this.$api.getUnitMemberList(inputData);
      this.memberList = [];
      this.$nextTick(() => {
        this.memberList = list.filter(item => item.status === 1)
      });
      this.pagingData.total = pages.total;
      // this.pagingData.offset = pages.offset;
      this.loadingStatus = false

      setTimeout(() => {
        for (let userId of this.selectedList) {
          let row = this.memberList.find(item => item.user_id === userId)
          if (row) {
            this.$refs.apeTable.defaultCheckedRow(row.id)
          }
        }
      }, 200)
    },

    //判断题数为0禁用
    checkSelectable(row) {
      // let count = 0
      // switch (this.questionType) {
      //   case "single_choice":
      //   case "judge":
      //   case "essay_questions":
      //   case "case_analysis":
      //   case "short":
      //   case "multiple_choice":
      //     count = row[this.questionType + '_count']
      //     break
      // }
      //
      // return count > 0
      return true
    },

    // 切换页码操作
    async switchPaging() {
      await this.initMemberList(NO_DEL)
      // console.log(this.selectedList, "selected")
      // setTimeout(() => {
      //   for (let userId of this.selectedList) {
      //     let row = this.memberList.find(item => item.user_id === userId)
      //     if (row) {
      //       this.$refs.apeTable.defaultCheckedRow(row.id)
      //     }
      //   }
      // }, 200)

    },

    // 清空选择
    onClearSelection() {
      this.selectedList = []
      this.$refs.apeTable.clearSelection()
      this.initMemberList(DEL)
    },

    async setSelectionList(list = null) {
      if (list !== null) {
        this.selectedList = list
      }
      await this.initMemberList(DEL)
      // setTimeout(() => {
      //   for (let userId of this.selectedList) {
      //     let row = this.memberList.find(item => item.user_id === userId)
      //     if (row) {
      //       this.$refs.apeTable.defaultCheckedRow(row.id)
      //     }
      //   }
      // }, 200)
    },

    // 全选
    onhandleSelectionAllChange(arr) {
      for (let row of this.memberList) {
        let index = this.selectedList.findIndex(item => item === row.user_id)
        if (index > -1) {
          this.selectedList.splice(index, 1)
        }
      }

      if (arr.length > 0) {
        for (let id of arr) {
          this.selectedList.push(id)
        }
      }
      // if (selected) {
      //   let row = this.memberList.find(item => item.id === id)
      //   this.selectedList.push(row.user_id)
      //   this.selectedList = Array.from(new Set(this.selectedList))
      // } else {
      //   for (let i of this.memberList) {
      //     let index = this.selectedList.findIndex(item => item == i.user_id)
      //     if (index > -1) {
      //       this.selectedList.splice(index, 1)
      //     }
      //   }
      //   this.$refs.apeTable.clearSelection()
      // }
    },

    // 单选
    handleSelectionChange(id, selected) {
      if (selected) {
        let row = this.memberList.find(item => item.id === id)
        this.selectedList.push(row.user_id)
      } else {
        let row = this.memberList.find(item => item.id === id)
        let pos = this.selectedList.findIndex(item => item === row.user_id)
        if (pos > -1) {
          this.selectedList.splice(pos, 1)
        }
      }

      console.log(this.selectedList)
    },

    async onFinish() {
      console.log(this.selectedList)
      this.$emit("finish", this.selectedList)
    }
  }
}
</script>

<style scoped lang="stylus">
.x-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center
}

.color-red {
  color red
}

.color-green {
  color green
}

.main-page-content {
  padding 0
}
</style>
<template>
  <div>
    <div>
      <el-radio-group v-model="selectedUnitId" @change="radioChange">
        <el-radio :label="item.id" v-for="(item, index) in unitList" :key="index" style="margin-bottom: 6px">{{ item.name }}</el-radio>
      </el-radio-group>
    </div>


    <div
        style="display: flex;justify-content: flex-end;width: 100%;margin-top: 24px;padding-top: 12px;border-top: 1px #cccccc solid">
      <el-button type="primary" @click="onSubmit">创建考试</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "select-unit",
  props: {
    unitList: {
      type: Array,
      default: () => {
        return []
      }
    },
  },

  data() {
    return {
      selectedUnitId: '',
    }
  },

  watch: {
  },

  mounted() {

  },

  methods: {
    resetAll() {
      this.selectedUnitId = ""
    },

    async onSubmit() {
      this.$emit("finish", this.selectedUnitId)
    },

    radioChange(e) {
      console.log(e)
    }
  }
}
</script>

<style scoped>

</style>
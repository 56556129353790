<template>
  <div>
    <el-form :model="myFormData" :rules="rules" label-width="90px">

      <el-form-item label="考试时长" prop="exam_time">
        <el-input v-model="myFormData.exam_time"
                  onkeyup="value=value.replace(/\D/g,'')"
                  :maxLength="3"
        >
          <template slot="append">分钟</template>
        </el-input>
      </el-form-item>

      <el-form-item label="考试题数">
        <el-input v-model="count" readonly>
          <template slot="append">题</template>
        </el-input>
      </el-form-item>

      <el-form-item label="考试总分">
        <el-input v-model="score" readonly>
          <template slot="append">分</template>
        </el-input>
      </el-form-item>

      <el-form-item label="及格分数" prop="pass_score">
        <el-input v-model="myFormData.pass_score"
                  onkeyup="value=value.replace(/\D/g,'')"
                  @input="onPassScoreChange">
          <template slot="append">分</template>
        </el-input>
      </el-form-item>

      <el-form-item label="考试人群">
        <el-radio-group v-model="examCrowdType">
          <el-radio label="all" border>全部</el-radio>
          <el-radio label="member" border>指定考生</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="考试学员" v-if="examCrowdType === 'member'">
        <div style="display: flex;align-items: center;">
          <div style="padding-right: 12px">已选择 <span
              style="color:#0099ff;padding: 0 6px">{{ memberList.length ? memberList.length : 0 }}</span>项
          </div>
          <el-button size="mini" type="primary" plain @click="onSelectMemberClick">选择学员</el-button>
        </div>
      </el-form-item>
    </el-form>

    <el-dialog :visible.sync="isShowSelectMemberDialog" :close-on-click-modal="false" width="80%" title="选择学员"
               :append-to-body="true" fullscreen style="margin: 10px" @opened="onMemberDialogOpened" :destroy-on-close="true">
      <select-member ref="selectMemberDialog" @finish="onMemberFinish"></select-member>
    </el-dialog>
  </div>
</template>

<script>
import SelectMember from "@/pages/unit_exam/compoments/SelectMember";

export default {
  name: "exam-setting",
  components: {SelectMember},
  props: {
    formData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    count: {
      type: String,
      default: ''
    },
    score: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      rules: {
        exam_time: [
          {required: true, message: '请输入考试时间', trigger: 'blur'},
        ],
        pass_score: [
          {required: true, message: '请输入及格分数', trigger: 'blur'},
        ],
      },
      memberList: [],
      isShowSelectMemberDialog: false,
      examCrowdType: '',
      myFormData: {},
      gId: '',
      unitId: ""
    }
  },

  watch: {
  },

  mounted() {
  },
  methods: {
    setGenerator(id) {
      this.gId = id
      if (this.gId) {
        this.getGenerateInfo()
      }
    },

    setUnitId(unitId) {
      this.unitId = unitId
    },
    onPassScoreChange(e) {
      // let val = e
      // if (val > this.score) {
      //   val = this.score
      //   this.myFormData.pass_score = val
      // }
      // console.log(this.myFormData, this.score)
    },

    async getGenerateInfo() {
      let {basic_info} = await this.$api.getGenerateInfo(this.gId)
      console.log(basic_info)
      if (basic_info.exam_user_id) {
        if (basic_info.exam_user_id === "all") {
          this.examCrowdType = "all"
        } else {
          this.examCrowdType = "member"
          this.memberList = basic_info.exam_user_id.split(",")
        }
      } else {
        this.examCrowdType = "all"
      }

      this.myFormData = basic_info
      let examTime = this.myFormData.exam_time || 150
      this.$set(this.myFormData, "exam_time", examTime)
      let passScore = this.myFormData.pass_score || Math.floor(this.score * 0.6)
      this.$set(this.myFormData, "pass_score", passScore)
    },

    //提交数据
    getForm() {
      if (this.examCrowdType === "member") {
        if (this.memberList.length <= 0) {
          this.$message.error("请选择考试学员")
          return
        }
        return this.myFormData
      } else {
        this.myFormData.exam_user_id = "all"
        return this.myFormData
      }
    },

    //打开选择学员弹窗
    onSelectMemberClick() {
      this.isShowSelectMemberDialog = true
    },
    onMemberDialogOpened() {
      let list = []
      if (this.$refs.selectMemberDialog) {
        this.$refs.selectMemberDialog.setUnitId(this.unitId)
        if (this.memberList && this.memberList.length > 0) {
          list = this.memberList
          this.$refs.selectMemberDialog.setSelectionList(list)
        } else {
          this.$refs.selectMemberDialog.setSelectionList([])
        }
      }

    },

    //选择学员完成点击
    onMemberFinish(list) {
      this.memberList = list
      this.myFormData.exam_user_id = this.memberList.join(",")
      this.isShowSelectMemberDialog = false
    },


    setMyFormData(data) {
      // if (!data.pass_score) {
      //   this.myFormData.pass_score =  0
      // }
      // // this.myFormData.pass_score = data.pass_score ? data.pass_score : 0
      // this.myFormData.exam_time = data.exam_time ? data.exam_time : 0
      //
      // if(!data.exam_user_id) {
      //   this.myFormData.exam_user_id = data.exam_user_id ? data.exam_user_id : "all"
      //   if (this.myFormData.exam_user_id === "all") {
      //     this.examCrowdType = "all"
      //   } else {
      //     this.examCrowdType = "member"
      //     this.memberList = this.myFormData.exam_user_id.split(",")
      //   }
      // }
      // this.myFormData.name = data.name
      // this.myFormData.start_at = data.start_at
      // this.myFormData.end_at = data.end_at
      // this.myFormData.status = data.status
      //
      //
      // console.log(this.myFormData)
    }
  }
}
</script>

<style scoped>

</style>
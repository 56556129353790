<template>
  <div>
    <div class="main-page-content">
      <el-row class="table-search">
        <el-form :inline="true" :model="formData" class="demo-form-inline">
          <el-form-item label="题库">
            <el-select v-model="formData.bankId" @change="onSelectQbChange" clearable>
              <el-option v-for="item in questionBankList"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="题目名称">
            <el-input placeholder="请输入题目名称" clearable v-model="formData.topic_alias" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSearchClick" style="margin-left: 12px">搜索</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="onResetSearch">重置</el-button>
          </el-form-item>
        </el-form>
      </el-row>

      <div style="display: flex;justify-content: flex-end;width: 100%">
        <div>
          <div style="display: flex;align-items: center;">
            <div style="padding-right: 12px">已选择 <span style="color:#0099ff;padding: 0 6px">{{
                selectedList.length
              }}</span>项
            </div>
            <el-button v-if="selectedList.length > 0" @click="onClearSelection" type="danger" size="mini" plain>清空已选数据
            </el-button>
            <el-button type="primary" size="mini" plain @click="onFinish" style="margin-left: 12px">完成</el-button>
          </div>
        </div>
      </div>

      <ape-table ref="apeTable" :data="questionList" :columns="columns" :loading="loadingStatus"
                 :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row
                 @select="handleSelectionChange" @selectAllOnce="onhandleSelectionAllChange">
        <el-table-column slot="first-column"
                         type="selection"
                         width="55">
        </el-table-column>
      </ape-table>

    </div>
  </div>
</template>

<script>
const DEL = "del";
const NO_DEL = "no_del";
import ApeTable from '@/components/ApeTable';

import {mapGetters} from 'vuex';

export default {
  name: "select-question",
  props: {
    questionType: {
      type: String,
      default: ""
    },
  },
  components: {ApeTable},
  data() {
    return {
      isView: false,
      loadingStatus: false,
      // 表格列表数据
      questionList: [],
      columns: [{
        title: '所属题库',
        value: "question_bank_name",
        width: '360'
      },
        {
          title: '题型',
          value: 'type_alias',
          width: '150'
        },
        {
          title: '题干',
          value: "topic_alias",
        },
      ],
      formData: {},
      searchForm: [],
      searchParams: {},

      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        // offset: 0, // 分页的offset,序号列使用
      },
      memberId: '',
      selectedList: [],
      questionBankList: [],
      curQbId: ''
    }
  },

  computed: {
    ...mapGetters(['userPermissions', 'buttonType', 'examChooseBankList'])
  },

  mounted() {
  },

 watch: {
   examChooseBankList: {
     handler(val) {
       if (val.length > 0) {
         if (!this.formData.bankId) {
           let firstBank = val[0]
           this.curQbId = firstBank.id
           this.formData.bankId = this.curQbId
         }
         this.questionBankList = val
         let curId = this.formData.bankId
         let index = this.questionBankList.findIndex(item => item.id === curId)
         if (index === -1) {
           this.formData.bankId = val.length > 0 ? val[0].id : ""
         }
         if (this.formData.bankId !== "") {
           this.initQuestionList()
         }
       }
     },
     immediate:true,
     deep:true
   },
 },

  methods: {
    onSelectQbChange(e) {
      this.curQbId = e
      // this.initQuestionList()
      this.setSelectionList(DEL)
    },
    //搜索
    onSearchClick() {
      this.$refs['apeTable'].resetCurPageSize();
      // this.curQbId = this.formData.bankId
      this.initQuestionList(DEL);
    },

    //重置搜索
    onResetSearch() {
      // this.curQbId = this.formData.bankId
      this.formData = {bankId: this.formData.bankId}
      this.initQuestionList(DEL);
    },

    async initQuestionList(type, params = {}) {
      if (this.formData.bankId === "") {
        return
      }

      if (! this.$refs['apeTable']) {
        return
      }

      this.loadingStatus = true;
      let inputData = this.$refs['apeTable'].getPagingInfo(type);
      for (let param in params) {
        inputData[param] = params[param];
      }
      inputData["qb_id"] = this.formData.bankId
      inputData["topic"] = this.formData.topic_alias //题目名称
      if (this.questionType) {
        inputData["type"] = this.questionType //题目名称
      }
      let {list, pages} = await this.$api.getQuestionBankQuestionList(inputData);
      this.questionList = [];
      this.$nextTick(() => {
        this.questionList = list
      });
      this.pagingData.total = pages.total;
      // this.pagingData.offset = pages.offset;
      this.loadingStatus = false

      setTimeout(() => {
        console.log(this.selectedList, "selectedList")
        for (let selected of this.selectedList) {
          this.$refs.apeTable.defaultCheckedRow(selected.question_id)
        }
      }, 200)
    },

    // 切换页码操作
    async switchPaging() {
      await this.initQuestionList(NO_DEL)
      console.log(this.selectedList, "selected")
      setTimeout(() => {
        for (let id of this.selectedList) {
          this.$refs.apeTable.defaultCheckedRow(id)
        }
      }, 200)
    },


    resetSearchFormData() {
      this.$nextTick(() => {
        this.searchParams = {}
        this.$refs['searchBox'].resetForm();
      })
    },

    resetAll() {
      this.resetFormData()
      this.resetSearchFormData()
    },

    onClearSelection() {
      this.selectedList = []
      this.$refs.apeTable.clearSelection()
      this.initQuestionList()
    },

    async setSelectionList(list = null) {
      if (list !== null) {
        this.selectedList = list
      }
      // this.$refs.apeTable.clearSelection()
      await this.initQuestionList()


    },

    // 全选
    onhandleSelectionAllChange(arr) {
      for (let i of this.questionList) {
        let index = this.selectedList.findIndex(item => item.question_id === i.id)
        if (index > -1) {
          this.selectedList.splice(index, 1)
        }
      }

      if (arr.length > 0) {
        for (let id of arr) {
          this.selectedList.push({bank_id: this.formData.bankId, question_id: id})
        }
      }

      // if (selected) {
      //   this.selectedList.push({bank_id: this.formData.bankId, question_id: id})
      //   this.selectedList = Array.from(new Set(this.selectedList))
      // } else {
      //   for (let i of this.questionList) {
      //     let index = this.selectedList.findIndex(item => item.question_id === i.id)
      //     if (index > -1) {
      //       this.selectedList.splice(index, 1)
      //     }
      //   }
      //   this.$refs.apeTable.clearSelection()
      // }
    },

    // 单选
    handleSelectionChange(id, selected) {
      if (selected) {
        this.selectedList.push({bank_id: this.formData.bankId, question_id: id})
      } else {
        let pos = this.selectedList.findIndex(item => item.question_id === id)
        if (pos > -1) {
          this.selectedList.splice(pos, 1)
        }
      }

      // console.log(this.selectedList)
    },

    async onFinish() {
      this.$emit("finish", this.selectedList, this.questionType)
    }
  }
}
</script>

<style scoped lang="stylus">
.x-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center
}

.color-red {
  color red
}

.color-green {
  color green
}

.main-page-content {
  padding 0
}
</style>
<template>
  <div v-if="questionList.length > 1">
    <div style="margin-right: 24px">
      <div
          style="background-color: rgba(0, 153, 255, 0.1);height: 60px;display:flex;align-items: center;justify-content: flex-end">
        <span v-show="detailType !== 'view' && questionDetail.score" style="padding-right: 12px">满分：{{questionDetail.score}}分</span>
        <div v-show="detailType !== 'view'">
          <div
              v-if="questionDetail.type !== 'multiple_choice' && questionDetail.type !== 'single_choice' && questionDetail.type !== 'judge'"
              style="margin-right: 90px;display: flex;flex-direction: row;">
<!--            <el-button :type="successBtnType" icon="el-icon-check" size="small"  style="margin-right: 6px" @click="onSuccessClick"></el-button>-->
<!--            <el-button :type="errorBtnType" icon="el-icon-close" size="small" @click="onErrorClick"></el-button>-->
            <el-popover
                placement="top-start"
                width="400"
                trigger="click">
              <div style="display: flex;flex-wrap: wrap;flex-direction: row">

                <div v-for="(item, index) in scoreOptionList" :key="index">
                <el-button type="primary" circle size="mimi" plain style="width: 50px;height: 50px;margin: 0 6px 12px 0;margin: 0 6px 12px 0" @click="onScoreOptionClick(item.value)">
                  {{ item.text }}</el-button>
                </div>
              </div>

              <div slot="reference" style="display: flex;flex-direction: row;align-items: center">
                <span style="color: #0099ff;flex-shrink: 0;padding: 0 12px">评分</span>
                <el-input v-model="score" style="width: 60px;" size="small" readonly></el-input>
                <span style="color: #999;flex-shrink: 0;padding-left: 12px">分</span>
              </div>

            </el-popover>
          </div>
          <div v-else>
            <span style="padding-right: 12px;margin-right: 90px">得分：{{questionDetail.user_score}}分</span>
          </div>
        </div>

        <el-button
            v-if="questionList.findIndex(item => (item.type === questionDetail.type && item.index === questionDetail.questionIndex) ) !== 0"
            type="primary" plain size="small" style="margin-right: 6px"
            @click="onLastQuestionClick(questionDetail.type, questionDetail.questionIndex)">
          上一题
        </el-button>
        <el-button
            v-if="questionList.findIndex(item => (item.type === questionDetail.type && item.index === questionDetail.questionIndex) ) !== (questionList.length - 1)"
            type="primary" plain size="small"
            @click="onNextQuestionClick(questionDetail.type, questionDetail.questionIndex)">
          下一题
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "toggle_question",
  components: {},
  props: {
    detailType: {
      type: String,
      default: "view"
    },
    questionList: {
      type: Array,
      default: () => {
        return []
      }
    },
    questionDetail: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data() {
    return {
      score: 0,
      scoreOptionList: [
        {value: 0, text: '0分'},
        {value: 100, text: '满分'},
        {value: 10, text: '10%'},
        {value: 20, text: '20%'},
        {value: 30, text: '30%'},
        {value: 40, text: '40%'},
        {value: 50, text: '50%'},
        {value: 60, text: '60%'},
        {value: 70, text: '70%'},
        {value: 80, text: '80%'},
        {value: 90, text: '90%'},
      ],
      successBtn: false,
      successBtnType: "info",
      errorBtn: false,
      errorBtnType: "info",
    }
  },
  watch: {
    successBtn: {
      handler(val) {
        if (val) {
          this.successBtnType = "success"
          this.errorBtn = false
        } else {
          this.errorBtn = true
          this.successBtnType = "info"
        }
      },
      immediate: true,
      deep:true
    },
    questionDetail: {
      handler(val) {
        if (val) {
          this.score = val.user_score
        }
      },
      immediate: true,
      deep:true
    },
    errorBtn: {
      handler(val) {
        if (val) {
          this.errorBtnType = "danger"
          this.successBtn = false
          this.score = 0
        } else {
          this.errorBtnType = "info"
          this.successBtn = true
        }

      },
      immediate: true,
      deep:true
    },
  },

  methods: {
    onLastQuestionClick(type, index) {
      let findIndex = this.questionList.findIndex(item => (item.type === type && item.index === index))
      if (findIndex === -1) {
        return
      }
      if (0 === findIndex) {
        return
      }
      let q = this.questionList[findIndex - 1]
      this.$emit("changeQuestion", q.type, q.index, q.name)
    },

    onNextQuestionClick(type, index) {
      let findIndex = this.questionList.findIndex(item => (item.type === type && item.index === index))
      if (findIndex === -1) {
        return
      }
      if ((this.questionList.length - 1) === findIndex) {
        return
      }
      let q = this.questionList[findIndex + 1]
      this.$emit("changeQuestion", q.type, q.index, q.name)
    },

    onScoreOptionClick(value) {
      // if (this.errorBtn) {
      //   this.successBtn = true
      // }
      let score = Math.floor(value * this.questionDetail.score / 100)
      this.$emit("scoreOptionClick", this.questionDetail.uueq_id, score, this.questionDetail.type, this.questionDetail.questionIndex, this.questionDetail.type_name)
    },

    onSuccessClick() {
      this.successBtn = !this.successBtn
    },

    onErrorClick() {
      this.errorBtn = !this.errorBtn
    }
  }
}
</script>

<style scoped>

</style>
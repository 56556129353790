<template>
  <div>
    <router-view v-show="$route.matched.length==3"></router-view>
    <PageHeaderLayout v-show="$route.matched.length==2">
      <div class="main-page-content">
        <el-button v-if="userPermissions.indexOf('member_create') != -1" type="primary" size="medium"
                   style="margin-bottom: 12px" @click="onCreateClick">添加学员
        </el-button>
        <el-row class="table-search">
          <el-form :inline="true" :model="searchForm" class="demo-form-inline">
            <el-form-item label="手机号码">
              <el-input placeholder="请输入手机号码" clearable v-model="searchForm.phone"/>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSearchClick" style="margin-left: 12px">搜索</el-button>
            </el-form-item>
            <el-form-item>
              <el-button @click="onResetSearch">重置</el-button>
            </el-form-item>
          </el-form>
        </el-row>

        <ape-table ref="apeTable" :data="traineeList" :columns="columns" :loading="loadingStatus"
                   :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row>

          <el-table-column
              label="状态"
              width="100"
          >
            <template slot-scope="scope">
              <span :class=" scope.row.status == 0 ? 'color-red':'color-green' ">{{ scope.row.status_alias }}</span>
            </template>
          </el-table-column>

          <el-table-column
              v-if="buttonType=='icon'"
              label="操作"
              width="200"
              fixed="right"
          >
            <template slot-scope="scope" v-if="userPermissions.length > 0">
              <span>
                <el-tooltip effect="dark" content="查看" placement="top-start"
                            v-if="userPermissions.indexOf('member_view') != -1">
                  <el-button size="mini" icon="el-icon-view" @click="onViewClick(scope.row.id)"></el-button>
                </el-tooltip>
                  <el-tooltip effect="dark" content="删除" placement="top-start"
                              v-if="userPermissions.indexOf('member_delete') != -1">
                  <el-button size="mini" type="danger" icon="el-icon-delete"
                             @click="onDeleteClick(scope.row)"></el-button>
                </el-tooltip>
              </span>
            </template>
          </el-table-column>

          <el-table-column
              v-if="buttonType=='text'"
              label="操作"
              width="200"
          >
            <template slot-scope="scope">
              <div>
                <el-button size="mini"
                           v-if="userPermissions.indexOf('member_view') != -1"
                           @click="onViewClick(scope.row.id)">查看
                </el-button>

                <el-button size="mini"
                           type="danger"
                           v-if="userPermissions.indexOf('member_delete') != -1"
                           @click="onDeleteClick(scope.row)">删除
                </el-button>
              </div>
            </template>
          </el-table-column>
        </ape-table>
      </div>
    </PageHeaderLayout>

    <ApeDrawer :drawerData="drawerData" @drawerClose="drawerClose" @drawerConfirm="drawerConfirm">
      <template slot="ape-drawer">
        <el-col class="content-left">
          <el-form ref="MemberForm" :model="memberForm" :rules="rules" label-width="100px">
            <div v-if="isView">
              <el-form-item label="学员编号" prop="id">
                <el-input v-model="memberForm.id" disabled></el-input>
              </el-form-item>
              <el-form-item label="用户编号" prop="id">
                <el-input v-model="memberForm.user_id" disabled></el-input>
              </el-form-item>
              <el-form-item label="用户昵称" prop="id">
                <el-input v-model="memberForm.nick_name" disabled></el-input>
              </el-form-item>
              <el-form-item label="所属单位" prop="id">
                <el-input v-model="memberForm.unit_name" disabled></el-input>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="手机号码" prop="phone">
                <el-input v-model="memberForm.phone" :maxlength="11"
                          oninput = "value=value.replace(/[^\d]/g,'')"
                          :disabled="isView"></el-input>
              </el-form-item>
              <el-form-item label="昵称">
                <el-input v-model="memberForm.nick_name" :maxLength="20" :disabled="isView"></el-input>
              </el-form-item>
            </div>


<!--            <div v-if="isView">-->
<!--              <el-form-item label="手机号码">-->
<!--                <el-input v-model="memberForm.phone" disabled></el-input>-->
<!--              </el-form-item>-->
<!--            </div>-->

            <el-form-item v-if="isView" label="创建时间" prop="id">
              <el-input v-model="memberForm.created_at" disabled></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </template>
    </ApeDrawer>
  </div>
</template>

<script>
const DEL = "del";
const NO_DEL = "no_del";
import PageHeaderLayout from '@/layouts/PageHeaderLayout';
import ApeTable from '@/components/ApeTable';
import ApeDrawer from '@/components/ApeDrawer';

import {mapGetters} from 'vuex';

export default {
  name: "trainee-exam-list",
  components: {PageHeaderLayout, ApeTable, ApeDrawer},
  data() {
    // 手机号验证
    let checkPhone = (rule, value, callback) => {
      const phoneReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (!value) {
        return callback(new Error('电话号码不能为空'))
      }
      setTimeout(() => {

        if (!Number.isInteger(+value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('电话号码格式不正确'))
          }
        }
      }, 100)
    };

    return {
      isView: false,
      loadingStatus: false,
      // 表格列表数据
      traineeList: [],
      columns: [
        {
          title: '学员编号',
          value: "id",
          width: '200'
        },
        {
          title: '昵称',
          value: "nick_name",
          width: '200'
        },
        {
          title: '手机号码',
          value: "phone",
          width: '120'
        },
        {
          title: '所属单位',
          value: 'unit_name',
          width: '120'
        },
        {
          title: "创建时间",
          value: 'created_at',
          width: '180'
        },
      ],
      formData: {},
      searchForm: {},
      searchParams: {},

      // 抽屉数据
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        title: '',
        width_height: '560px',
      },

      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        // offset: 0, // 分页的offset,序号列使用
      },
      memberForm: {},
      // 表单验证
      rules: {
        phone: [
          {required: true, validator: checkPhone, trigger: 'blur'},
          {min: 11, message: '请输入正确的手机号码', trigger: 'blur'},
        ],
      },
      memberId: '',
    }
  },

  computed: {
    ...mapGetters(['userPermissions', 'buttonType', 'curChooseBankId', 'questionBankInfo', 'curChooseBankName'])
  },

  mounted() {
    this.initMemberList();
  },

  watch: {
  },

  methods: {
    //搜索
    onSearchClick() {
      this.$refs['apeTable'].resetCurPageSize();
      this.initMemberList(DEL, this.searchForm);
    },

    //重置搜索
    onResetSearch() {
      this.searchForm = {};
      this.initMemberList();
    },

    async initMemberList(type, params = {}) {
      this.loadingStatus = true;
      let inputData = this.$refs['apeTable'].getPagingInfo(type);
      for (let param in params) {
        inputData[param] = params[param];
      }
      let {list, pages} = await this.$api.getUnitMemberList(inputData);
      this.traineeList = [];
      this.$nextTick(() => {
        this.traineeList = list
      });
      this.pagingData.total = pages.total;
      // this.pagingData.offset = pages.offset;
      this.loadingStatus = false
    },

    // 切换页码操作
    async switchPaging() {
      this.initMemberList(NO_DEL)
    },

    // 添加
    onCreateClick() {
      this.memberForm = {}
      this.$refs['MemberForm'].resetFields();
      this.isView = false
      this.memberId = ''
      this.drawerData.loading_text = '玩命加载中……';
      this.drawerData.visible = true;
      this.drawerData.title = '新增学员';

      this.$nextTick(() => {
        this.drawerData.loading = false;
      })
    },

    // 查看
    async onViewClick(id) {
      if (!id) {
        return
      }

      this.memberForm.id = id
      this.traineeId = id
      this.drawerData.loading_text = '玩命加载中……';
      this.drawerData.visible = true;
      this.drawerData.title = '学员详情';
      this.isView = true


      let {info} = await this.$api.getUnitMember({id});
      this.$nextTick(() => {
        this.drawerData.loading = false;
        this.memberForm = info;
      })
    },

    // 删除
    onDeleteClick(row) {
      let id = row.id
      let message = ""
      if (row.status !== 0) {
        message = "该学员已激活，是否确认删除该学员?"
      } else {
        message = "是否删除该学员?"
      }

      this.$confirm(message, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let number = await this.$api.deleteUnitMember({id});
        if (number) {
          this.$nextTick(() => {
            this.$message.success("删除成功，还剩余" + number + "个名额")
            this.initMemberList(DEL);
          })
        } else {
          this.$message.error("删除失败")
        }
      })
    },

    // 初始化数据
    resetFormData() {
      this.$nextTick(() => {
        this.$refs['MemberForm'].resetFields();
      })
    },

    // 处理抽屉关闭+
    drawerClose() {
      this.resetFormData();
      // this.isReadOnly = true;
      this.drawerData.visible = false;
      this.isView = false
    },

    // 处理抽屉确认
    async drawerConfirm() {
      if (this.isView === true) {
        this.drawerClose()
        return
      }


      //调用组件的数据验证方法
      this.$refs['MemberForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
    },

    // form数据提交，请求接口
    async formSubmit() {
      this.drawerData.loading_text = '玩命提交中……';
      this.drawerData.loading = true;
      let phone = this.memberForm.phone.trim()
      this.$set(this.memberForm, "phone", phone)
      let number = await this.$api.saveUnitMember(this.memberForm).catch(() => {
        this.$message.error("添加失败")
      });
      await this.initMemberList()
      this.$message.success("添加成功，还剩余" + number + "个名额")

      this.$nextTick(() => {
        this.drawerData.visible = false
      });
      this.isView = false
      this.resetFormData()
    },

  }
}
</script>

<style scoped lang="stylus">
.x-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center
}

  .color-red{
    color red
  }

  .color-green{
    color green
  }
</style>
<template>
  <div style="padding: 12px 30px">
    <div style="font-size: 14px;color: #F56C6C">* <span style="color: #333">设置题数</span>
      <span style="padding-left: 24px;color: #333333">
                    已选<span style="color:#0099ff;padding: 0 6px">{{ totalQuestionCount }}</span>题，共
                    <span style="color:#0099ff;padding: 0 6px">{{ totalQuestionScore }}</span>分
                  </span>
    </div>

    <div style="padding: 30px">
      <div v-for="(item, index) in myQuestionType" :key="index" class="x-row" style="margin-bottom: 12px">
        <div style="width: 310px" class="x-row">
          <template v-if="testPaperType === 'random'">
            <span style="padding-right: 12px">{{ item.text }}(共{{ bankTypeCountMap[item.id] || 0 }}题)</span>
          </template>
          <template v-if="testPaperType === 'chapter'">
            <span style="padding-right: 12px">{{ item.text }}(共{{ chapterTypeCountMap[item.id] || 0 }}题)</span>
            <el-button plain size="mini" @click="onSelectChapterClick(item)">选择章节</el-button>
            <span v-if="item.list && item.list.length > 0" style="padding: 0 12px;color: #999999">已选<span
                style="color:#0099ff;padding: 0 6px">{{ item.list.length }}</span>项</span>
          </template>
          <template v-if="testPaperType === 'manual'">
            <span style="padding-right: 12px">{{ item.text }}(共{{ bankTypeCountMap[item.id] || 0 }}题)</span>
            <el-button plain size="mini" @click="onSelectQuestionClick(item)">选择题目</el-button>
            <span v-if="item.list && item.list.length > 0" style="padding: 0 12px;color: #999999">已选<span
                style="color:#0099ff;padding: 0 6px">{{ item.list.length }}</span>项</span>
          </template>

        </div>
        <div class="x-row">
          <div>
            <span>{{ testPaperType === 'manual' ? '已选' : '抽取' }}</span>
            <el-input style="width: 90px;padding: 0 6px;text-align: center"
                      :value="item.question_count"
                      @input="onInputCount(item, $event)"
                      @blur="onInputCountBlur(item, $event)"
                      :disabled="testPaperType === 'manual'"></el-input>
          </div>
          <span>题</span>
        </div>
        <div class="x-row" style="margin-left: 60px;">
          <span>每题</span>
          <el-input style="width: 90px;padding: 0 6px;text-align: center"
                    :value="item.question_score"
                    :maxLength="2"
                    :disabled="item.question_count === 0"
                    @input="onInputScore(item, $event)"
          ></el-input>
          <span>分</span>
        </div>
        <div class="x-row" style="margin-left: 60px">
          <el-button v-if="item.question_count || item.question_score" type="text" @click="resetForm(item)">清空
          </el-button>
        </div>
      </div>
    </div>

    <el-switch
        v-if="testPaperType !== 'manual'"
        v-model="isSwitch"
        active-text="开启后，每个考生将看到不同的试卷，尽可能防止考生作弊，关闭后，使用统一试卷进行考试"
        inactive-text="千人千卷">
    </el-switch>

    <el-switch
        v-if="testPaperType === 'manual'"
        v-model="isSwitch"
        active-text="开启后，每个考生考试题目乱序，尽可能防止考生作弊，关闭后，使用统一试卷进行考试"
        inactive-text="题目乱序">
    </el-switch>

    <el-dialog :visible.sync="isShowSelectQuestionDialog" :close-on-click-modal="false" width="80%" title="选择题目"
               :append-to-body="true" fullscreen style="margin: 10px" @opened="onQuestionDialogOpened" :destroy-on-close="true">
      <select-question ref="selectQuestion" @finish="onSelectQuestionFinish"
                       :question-type="questionTypeText"></select-question>
    </el-dialog>

    <el-dialog :visible.sync="isShowSelectChapterDialog" :close-on-click-modal="false" width="80%" title="选择章节"
               :append-to-body="true" fullscreen style="margin: 10px" @opened="onChapterDialogOpened" :destroy-on-close="true">
      <select-chapter ref="selectChapter" @finish="onSelectChapterFinish"
                      :question-type="questionTypeText"></select-chapter>
    </el-dialog>

  </div>
</template>

<script>
import SelectQuestion from "@/pages/unit_exam/compoments/SelectQuestion";
import SelectChapter from "@/pages/unit_exam/compoments/SelectChapter";
import {mapGetters} from "vuex";

export default {
  name: "question-type-set",
  components: {SelectQuestion, SelectChapter},
  props: {
    testPaperType: {
      type: String,
      default: 'random'
    },
    questionType: {
      type: Array,
      default: () => {
        return []
      }
    },
    selectedBankList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      myQuestionType: [],
      isSwitch: false,
      isShowSelectQuestionDialog: false,
      isShowSelectChapterDialog: false,
      formData: {},
      type: "",
      questionTypeText: '', //点击题型,
      selectedQuestionType: {},
      bankTypeCountMap: {
        "single_choice": 0,
        "multiple_choice": 0,
        "case_analysis": 0,
        "essay_questions": 0,
        "judge": 0,
        "short": 0,
      },
      chapterTypeCountMap: {
        "single_choice": 0,
        "multiple_choice": 0,
        "case_analysis": 0,
        "essay_questions": 0,
        "judge": 0,
        "short": 0,
      }
    }
  },

  watch: {
    questionType: {
      handler(list) {
        this.myQuestionType = list
        // for (let i of this.myQuestionType) {
        //   if (!i.question_count) {
        //     i.question_count = 0
        //     i.question_score = 0
        //   }
        //   if (!i.count) {
        //     i.count = 0
        //   }
        // }
      },
      immediate: true,
      deep: true
    },
    selectedBankList: {
      handler(list, oldList) {
        console.log("list")
        this.resetBankTypeCountMap()
        for (let bank of list) {
          for (let key in this.bankTypeCountMap) {
            if (key + "_count" in bank) {
              this.bankTypeCountMap[key] += bank[key + "_count"]
            }
          }
        }

        if (JSON.stringify(list) === JSON.stringify(oldList)) {
          return
        }

        // console.log(list, oldList, "oldList")
        // for (let type of this.myQuestionType) {
        //   let qCount = type.question_count
        //   let qbQuestionCount = this.getTypeQuestionCount(type.id)
        //
        //   if (qCount > qbQuestionCount) {
        //     type.question_count = qbQuestionCount
        //   }
        // }

        console.log(this.bankTypeCountMap, "map")
      },
      immediate: true,
      deep: true
    },
    myQuestionType: {
      handler(val) {
        this.$emit("change", val)
      },
      immediate: true,
      deep: true
    }
  },

  computed: {
    ...mapGetters(['userPermissions', 'buttonType', 'examChooseBankList']),
    // 计算题数
    totalQuestionCount() {
      let val = 0
      for (let i = 0; i < this.myQuestionType.length; i++) {
        let count = this.myQuestionType[i].question_count || 0
        val += count;
      }
      return val
    },
    // 计算分数
    totalQuestionScore() {
      let val = 0
      for (let i = 0; i < this.myQuestionType.length; i++) {
        let count = this.myQuestionType[i].question_count || 0
        let score = this.myQuestionType[i].question_score || 0
        val += count * score;
      }
      return val
    },
  },

  mounted() {
    this.type = this.testPaperType
  },

  methods: {
    reCalcQuestionTypeSet(bankList) {
      for (let type of this.myQuestionType) {
        let qCount = type.question_count
        let qbQuestionCount = this.getTypeQuestionCount(type.id)

        if (qCount > qbQuestionCount) {
          type.question_count = qbQuestionCount
        }
        if (type.question_count === 0) {
          type.question_score = 0
        }

        let list = type.list
        if (! list || this.testPaperType === "random") {
          continue
        }

        let newList = []
        for (let item of list) {
          let bankId = item.bank_id
          if (bankList.findIndex(bankItem => bankItem.id === bankId) >= 0) {
            newList.push(item)
          }
        }
        type.list = newList
        let index = this.myQuestionType.findIndex(t => t.id === type.id)
        if (index > -1) {
          this.$set(this.myQuestionType, index, type)
        }

        if (this.testPaperType === "chapter") {

          this.onSelectChapterFinish(newList, type.id)
        } else if (this.testPaperType === "manual") {

          this.onSelectQuestionFinish(newList, type.id)
        }
      }
    },
    setSelectedRandomQuestionList(data) {
      if (!data || !data.rule) {
        return
      }

      for (let type of this.myQuestionType) {
        let typeId = type.id
        type.question_count = 0
        type.question_score = 0
        let typeRule = data.rule[typeId]
        if (typeRule) {
          type.question_count = typeRule.question_count
          type.question_score = typeRule.score
          if (typeRule["question_bank_list"]) {
            type.list = typeRule["question_bank_list"]
          }
        }
      }
      this.isSwitch = data.random
    },
    setSelectedChapterQuestionList(data) {
      if (!data || !data.rule) {
        return
      }
      for (let type of this.myQuestionType) {
        let typeId = type.id
        type.question_count = 0
        type.question_score = 0
        let typeRule = data.rule[typeId]
        if (typeRule) {
          type.question_count = typeRule.question_count
          type.question_score = typeRule.score
          console.log(typeRule["chapter_list"])
          if (typeRule["chapter_list"]) {
            type.list = typeRule["chapter_list"]
          }
        }
      }
      this.isSwitch = data.random
    },
    setSelectedManualQuestionList(data) {
      if (!data || !data.rule) {
        return
      }

      console.log("manual")

      for (let type of this.myQuestionType) {
        let typeId = type.id
        type.question_count = 0
        type.question_score = 0
        let typeRule = data.rule[typeId]
        if (typeRule) {
          type.question_score = typeRule.score
          if (typeRule["question_list"]) {
            type.list = typeRule["question_list"]
            type.question_count = type.list?.length
          }
        }
      }
      this.isSwitch = data.random
    },


    resetBankTypeCountMap() {
      this.bankTypeCountMap = {
        "single_choice": 0,
        "multiple_choice": 0,
        "case_analysis": 0,
        "essay_questions": 0,
        "judge": 0,
        "short": 0,
      }
    },
    resetChapterTypeCountMap() {
      this.chapterTypeCountMap = {
        "single_choice": 0,
        "multiple_choice": 0,
        "case_analysis": 0,
        "essay_questions": 0,
        "judge": 0,
        "short": 0,
      }
    },

    getTypeQuestionCount(typeId) {
      let count = 0
      switch (this.testPaperType) {
        case "random":
        case "manual":
          count = this.bankTypeCountMap[typeId]
          break;
        case "chapter":
          count = this.chapterTypeCountMap[typeId]
          break
      }

      return count
    },

    onInputCount(item, e) {
      let qCount = this.getTypeQuestionCount(item.id)
      if (this.testPaperType === 'chapter' && !qCount) {
        this.$message.error("题目不足，请选择其他章节")
        return
      }

      if (this.testPaperType === 'random' && !qCount) {
        this.$message.error("题目不足，请选择其他题库")
        return
      }
      if (e.length > 0) {
        var reg = /^[0-9]+.?[0-9]*$/;
        if (!reg.test(e)) {
          console.log("我不是数字", e)
          return;
        }
      }

      console.log(e, "e")
      let v = e
      if (v > qCount) {
        v = qCount
      }
      v = v ? parseInt(v) : ""

      this.$set(item, 'question_count', v)
      let index = this.myQuestionType.findIndex(t => t.id === item.id)
      if (index > -1) {
        this.$set(this.myQuestionType, index, item)
      }
    },

    onInputCountBlur(item, e) {
      // if(item.question_count && item.question_score === 0) {
      //   this.$message.error("请设置" + item.text + "题目分数")
      // }
    },
    onInputScore(item, e) {
      if (e.length > 0) {
        var reg = /^[0-9]+.?[0-9]*$/;
        if (!reg.test(e)) {
          console.log("我不是数字", e)
          return;
        }
      }

      let v = e ? parseInt(e) : ""
      this.$set(item, 'question_score', v)
      let index = this.myQuestionType.findIndex(t => t.id === item.id)
      if (index > -1) {
        this.$set(this.myQuestionType, index, item)
      }
    },


    // 打开选择题目dialog
    onSelectQuestionClick(data) {
      if (this.getTypeQuestionCount(data.id) === 0) {
        this.$message.error("题目不足，请选择其他题库")
        return
      }
      this.selectedQuestionType = data
      this.questionTypeText = data.id
      this.isShowSelectQuestionDialog = true
    },
    onQuestionDialogOpened() {
      let data = this.selectedQuestionType
      if (data.list && data.list.length > 0) {
        let newList = []
        for (let item of data.list) {
          newList.push({
            bank_id: item.bank_id,
            question_id : item.question_id
          })
        }
        this.$refs.selectQuestion.setSelectionList(newList)
      } else {
        this.$refs.selectQuestion.setSelectionList([])
      }
    },

    //打开选择章节dialog
    onSelectChapterClick(data) {
      this.selectedQuestionType = data
      this.isShowSelectChapterDialog = true
    },
    onChapterDialogOpened() {
      let data = this.selectedQuestionType
      this.questionTypeText = data.id

      if (data.list && data.list.length > 0) {
        let newList = []
        for (let item of data.list) {
          newList.push({
            bank_id: item.bank_id,
            chapter_id : item.id
          })
        }
        this.$refs.selectChapter.setSelectionList(newList)
      } else {
        this.$refs.selectChapter.setSelectionList([])
      }
    },

    //清空当前题型
    resetForm(item) {
      item.question_count = 0
      item.question_score = 0
      item.list = []
      this.$set(item, 'question_count', 0)
      this.$set(item, 'question_score', 0)
      this.$set(item, 'list', [])
      let index = this.myQuestionType.findIndex(t => t.id === item.id)
      if (index > -1) {
        this.$set(this.myQuestionType, index, item)
      }
    },

    // 选择题目完成操作
    onSelectQuestionFinish(list, type) {
      let index = this.myQuestionType.findIndex(item => item.id === type)
      this.isShowSelectQuestionDialog = false
      if (index === -1) {
        return
      }
      this.myQuestionType[index].list = list
      this.myQuestionType[index].question_count = list.length
    },

    // 选择章节完成操作
    onSelectChapterFinish(list, type) {
      this.resetChapterTypeCountMap()
      for (let chapter of list) {
        console.log(this.chapterTypeCountMap, chapter, "chapter")
        for (let key in this.chapterTypeCountMap) {
          if (key + "_count" in chapter) {
            this.chapterTypeCountMap[key] += chapter[key + "_count"]
          }
        }
      }

      let index = this.myQuestionType.findIndex(item => item.id === type)
      this.isShowSelectChapterDialog = false
      if (index === -1) {
        return
      }
      this.myQuestionType[index].list = list
    },

    submitForm() {
      this.formData.rule = {}
      let checkedQuestionCount = 0
      for (let i of this.myQuestionType) {
        this.formData.rule[i.id] = {}
        this.formData.rule[i.id]["score"] = i.question_score ? i.question_score : 0
        this.formData.rule[i.id]["question_count"] = i.question_count ? i.question_count : 0

        checkedQuestionCount += this.formData.rule[i.id]["question_count"]

        if (this.testPaperType === 'chapter') {
          let arr = []
          if (i.list && i.list.length > 0) {
            for (let item of i.list) {
              arr.push({
                bank_id: item.bank_id,
                id: item.id,
              })
            }
            this.formData.rule[i.id]["chapter_list"] = arr
          } else {
            this.formData.rule[i.id]["chapter_list"] = []
          }
        } else if (this.testPaperType === 'manual') {
          if (i.list && i.list.length > 0) {
            let arr = []
            for (let item of i.list) {
              arr.push({
                bank_id: item.bank_id,
                id: item.question_id,
              })
            }
            this.formData.rule[i.id]["question_list"] = arr
          } else {
            this.formData.rule[i.id]["question_list"] = []
          }
        } else if (this.testPaperType === 'random') {
          this.formData.rule[i.id]["question_bank_list"] = this.examChooseBankList.map(item => item.id)
        }

        if (this.formData.rule[i.id]["question_count"] === 0 && this.formData.rule[i.id]["score"] > 0) {
          this.$message.error("请设置" + i.text + "题目数量")
          return
        }

        if (this.formData.rule[i.id]["score"] === 0 && this.formData.rule[i.id]["question_count"] !== 0) {
          this.$message.error("请设置" + i.text + "目分数")
          return
        }
      }

      if (checkedQuestionCount === 0) {
        this.$message.error("请设置题目")
        return
      }

      this.formData.random = this.isSwitch
      this.formData.type = this.testPaperType
      this.formData.question_bank_list = this.examChooseBankList.map(item => item.id)


      let data = {}
      data.form = this.formData
      data.count = this.totalQuestionCount
      data.score = this.totalQuestionScore
      return data
    },

    // setQuestionTypeCount(data) {
    //   this.setCount("case_analysis", data)
    //   this.setCount("essay_questions", data)
    //   this.setCount("judge", data)
    //   this.setCount("multiple_choice", data)
    //   this.setCount("single_choice", data)
    //   this.setCount("short", data)
    // },
    //
    // setCount(val, data) {
    //   let index = this.myQuestionType.findIndex(item => item.id === val)
    //   if (index === -1) {
    //     if (val !== 'short') {
    //       return
    //     } else {
    //       this.myQuestionType.push({id: val, text: "简答题", count: data[val]})
    //       index = this.myQuestionType.findIndex(item => item.id === val)
    //       if (index === -1) {
    //         return
    //       }
    //     }
    //   }
    //   this.myQuestionType[index].count = data[val]
    //
    // }
  }
}
</script>

<style scoped lang="stylus">
.x-row {
  display: flex;
  flex-direction: row;
  align-items: center
}
</style>